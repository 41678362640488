import { createSlice } from "@reduxjs/toolkit";
import { createAdminOfficeEmailLocation, getAdminOfficeEmailLocation } from "../ApiActions/officeLocationsApi";


const initialState = {
  officeLocation: [],
  getOfficeLocation: [],
  isFecthing: false,
  error: "",
};

const location = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: (obj) => {
    obj.addCase(createAdminOfficeEmailLocation.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(createAdminOfficeEmailLocation.fulfilled, (state, action) => {
      // console.log("Fulfilled", action.payload);
      state.isFecthing = false;
      state.officeLocation = action.payload;
    });
    obj.addCase(createAdminOfficeEmailLocation.rejected, (state, action) => {
      console.log("rejected");
      state.isFecthing = false;
      state.error = action.error;
    });
    obj.addCase(getAdminOfficeEmailLocation.pending, (state)=>{
    })
    obj.addCase(getAdminOfficeEmailLocation.fulfilled, (state, action)=>{
       state.getOfficeLocation = action.payload;
    })
    obj.addCase(getAdminOfficeEmailLocation.rejected, (state, action)=>{
       state.error = action.error;
    })
  },
});
export default location.reducer;