import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../ApiRoute/ApiRoute";

export const createWorkforce = createAsyncThunk(
  //action type string
  "employeeworkforce/createEmployeeWorkforce",
  //callback func
  async (data, { rejectWithValue }) => {
    try {
      const response = await userRequest.post("/insertEmployee", data);
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue("error occured while Creating");
    }
  }
);
export const getWorkforce = createAsyncThunk(
  //action type string
  "employeeworkforce/getEmployeeWorkforce",
  //callback func
  async (data, { rejectWithValue }) => {
    try {
      let res = await userRequest.get("/selectEmployee");
      console.log("response>>>",res);
      return res.data.data;
    } catch (err) {
      return rejectWithValue("error occured while fetching");
    }
  }
);
export const updateWorkforce = createAsyncThunk(
  // action type string
  "employeeworkforce/updateEmployeeWorkforce",
  //  callback func
  async (data, { rejectWithValue }) => {
    try {
      return await userRequest.post("/updateEmployeeFormAdmin", data);
    } catch (err) {
      return rejectWithValue("error occured while updating");
    }
  }
);
