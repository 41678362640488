import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import Buttons from "../Button/Buttons";
import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMediaQuery } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import Webcam from "react-webcam";
import { useLocation } from "react-router-dom";
import {
  afterSubmitFields,
  afterSubmitFieldsMobile,
} from "../../Utils/dataUtils";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const Forms = (props) => {
  const {
    inputFields,
    onFinish,
    showDropdown,
    selected,
    selectType,
    selectWorkforce,
    setSelected,
    setShowDropdown,
    title,
    basicFormData,
    basicForm,
    allWorkforceData,
  } = props;

  const [userData, setUserData] = useState(null);
  const [displaySubmittedData, setDisplaySubmittedData] = useState([]);
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 750px)");
  const [isNotImg, setIsNotImg] = useState(false);
  const [imageModal, setImageModal] = useState({
    isImage: false,
    imageField: null,
  });
  console.log("basicForm", basicForm);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
  });
  const [fieldNames, setFieldNames] = useState([]);
  const [fieldNamesCapture, setFieldNamesCapture] = useState([]);
  const [profileImage, setProfileImage] = useState({
    imageData: {},
    imageUrl: null,
  });
  console.log("profileImage>>", fieldNamesCapture);
  const [insuranceImage, setInsuranceImage] = useState({
    imageData: {},
    imageUrl: null,
  });
  const [cglImage, setCglImage] = useState({
    imageData: {},
    imageUrl: null,
  });
  const [vehicleImage, setVehicleImage] = useState({
    imageData: {},
    imageUrl: null,
  });
  console.log("profileImage", profileImage);
  // console.log("profileImage>>", vehicleImage);

  const [isAdmin, setIsAdmin] = React.useState(null);

  const getUserData = () => {
    const userEmail = localStorage.getItem("userEmail");

    const loginUser = allWorkforceData?.find(
      (item) => userEmail === item.email
    );
    //send userdata as a prop in afterSubmitFields array
    setDisplaySubmittedData(
      isMobile
        ? afterSubmitFieldsMobile(loginUser)
        : afterSubmitFields(loginUser)
    );
    setUserData(loginUser);
  };

  useEffect(() => {
    getUserData();
  }, [allWorkforceData]);

  var decoded;

  function decoder() {
    var token = localStorage.getItem("token");
    decoded = jwtDecode(token);
    console.log("decoded ==>", decoded);

    if (decoded == null) {
    } else {
      // localStorage.setItem("username", decoded.tokenData.username);
      // localStorage.setItem("userId", decoded.tokenData.id);
      // localStorage.setItem("userEmail", decoded.tokenData.email);
      var user = localStorage.getItem("username");
      setIsAdmin(decoded.tokenData.role);
      return user;
    }
  }

  React.useEffect(() => {
    decoder();
  }, []);

  const isGetName = basicFormData && Object.keys(basicFormData).length !== 0;
  // const isDataInLS =
  //   basicForm?.profile && Object.keys(basicForm.profile).length !== 0;
  const isDataInLS = basicForm?.fName;
  console.log("basicForm", isDataInLS);
  if (isGetName && !formData.firstName && !formData.lastName) {
    const { fName, lName } = basicFormData;
    setFormData({ firstName: fName, lastName: lName });
  }
  const numberFields = [
    "ssn",
    "ein",
    "phone",
    "contactPhone1",
    "contactPhone2",
    "year",
    "routing",
    "confirmRouting",
    "account",
    "confirmAccount",
  ];

  const maxFileSize = 2; // in megabytes
  const acceptedFileTypes = ["image/jpeg", "image/png", "image/jpg"];

  // Define a function to check if the file size is within limits
  const isFileWithinSizeLimit = (file) => {
    return file.size / 1024 / 1024 <= maxFileSize;
  };

  // Define a function to check if the file type is accepted
  const isFileTypeAccepted = (file) => {
    return acceptedFileTypes.includes(file.type);
  };

  const uploadProps = (fieldName) => ({
    name: "file",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    // action: "http://localhost:3001/api/incident/createIncident",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      console.log("fieldName", fieldName);
      const isLtMaxSize = isFileWithinSizeLimit(file);
      const isFileTypeValid = isFileTypeAccepted(file);

      if (!isLtMaxSize) {
        message.error(`File must be smaller than ${maxFileSize} MB!`);
        return false;
      }
      if (!isFileTypeValid) {
        message.error(`Invalid file type! only accept JPG/PNG/Jpeg}`);
        return false;
      }
      if (isFileTypeValid && isLtMaxSize) {
        message.success(`Image Uploaded`);
        return true;
      }
      // return true; // Proceed with upload
    },
    onChange: (info) => {
      //check if originFileObj is present
      const fileData = info.file.originFileObj
        ? info.file.originFileObj
        : info.file;
      //check size and type
      const isLtMaxSize = isFileWithinSizeLimit(fileData);
      const isFileTypeValid = isFileTypeAccepted(fileData);

      if (isLtMaxSize && isFileTypeValid) {
        getBase64(info.file.originFileObj, (url) => {
          //set field name in this array state
          setFieldNames((prev) => [...prev, fieldName]);
          if (fieldName === "profile") {
            setProfileImage({
              imageData: fileData,
              imageUrl: url,
            });
          } else if (fieldName === "insuranceImage") {
            setInsuranceImage({
              imageData: fileData,
              imageUrl: url,
            });
          } else if (fieldName === "cglinsuranceImage") {
            setCglImage({
              imageData: fileData,
              imageUrl: url,
            });
          } else if (fieldName === "vehicleImage") {
            setVehicleImage({
              imageData: fileData,
              imageUrl: url,
            });
          }
        });
      } else {
        // Empty the fileList to clear the selected file
        info.fileList = [];
      }
    },
  });
  const [image, setImage] = useState(null);
  console.log("image>>", image);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);

  const startCamera = async (fieldName) => {
    try {
      setFieldNamesCapture((prev) => [...prev, fieldName]);

      const constraints = { video: { facingMode: "environment" } }; // Use 'user' for front camera
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }

      // Store the stream reference
      streamRef.current = stream;

      // Stop camera function
      const stopCamera = () => {
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
        }
      };

      // Call stopCamera when unmounting or when you want to stop the camera
      return stopCamera;
    } catch (error) {
      console.error("Error accessing the camera:", error);
      alert(
        "Failed to access the camera. Please make sure you have given permission and try again."
      );
      return () => {}; // Return an empty function if there was an error
    }
  };

  const captureImage = (fieldName) => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Try to compress the image if it's larger than the max file size
      const compressImage = (quality, callback) => {
        canvas.toBlob(
          (blob) => {
            if (blob.size / 1024 / 1024 > maxFileSize && quality > 0.1) {
              // If the blob size is too large and quality is not too low, try again with lower quality
              compressImage(quality - 0.1, callback);
            } else {
              // When the size is within limit or quality too low, call the callback
              callback(blob);
            }
          },
          "image/jpeg",
          quality
        );
      };
      compressImage(1, (compressedBlob) => {
        const compressedFile = new File(
          [compressedBlob],
          `capture_${Date.now()}.jpeg`,
          {
            type: "image/jpeg",
          }
        );

        // Do something with the compressedFile here
        // ...

        // If you want to set the image in your state
        setImage(compressedFile);
        console.log("image>>", compressedFile);
        console.log("compressedFile", compressedFile);
        getBase64(compressedFile, (url) => {
          //set field name in this array state

          setFieldNames((prev) => [...prev, fieldName]);
          if (fieldName === "profile") {
            setProfileImage({
              imageData: compressedFile,
              imageUrl: url,
            });
          } else if (fieldName === "insuranceImage") {
            setInsuranceImage({
              imageData: compressedFile,
              imageUrl: url,
            });
          } else if (fieldName === "cglinsuranceImage") {
            setCglImage({
              imageData: compressedFile,
              imageUrl: url,
            });
          } else if (fieldName === "vehicleImage") {
            setVehicleImage({
              imageData: compressedFile,
              imageUrl: url,
            });
          }
        });
      });
    }

    // Stop the camera stream
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }

    // Update the field names state
    setFieldNamesCapture((prevFieldNames) =>
      prevFieldNames.filter((item) => item !== fieldName)
    );
    setFieldNames((prev) => [...prev, fieldName]);
  };

  const offCamera = (fieldName) => {
    // Stop the camera stream
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    }
    // Update the field names state
    setFieldNamesCapture((prevFieldNames) =>
      prevFieldNames.filter((item) => item !== fieldName)
    );
  };

  const showModal = (fieldName) => {
    // props.form.resetFields();
    setImageModal({ isImage: true, imageField: fieldName });
  };

  const deleteImg = (fieldNameToRemove) => {
    //empty all states related to image
    setFieldNames((prevFieldNames) =>
      prevFieldNames.filter((item) => item !== fieldNameToRemove)
    );
    switch (fieldNameToRemove) {
      case "profile":
        setProfileImage({
          imageData: {},
          imageUrl: {},
        });
        break;
      case "insuranceImage":
        setInsuranceImage({
          imageData: {},
          imageUrl: {},
        });
        break;
      case "cglinsuranceImage":
        setCglImage({
          imageData: {},
          imageUrl: {},
        });
        break;
      case "vehicleImage":
        setVehicleImage({
          imageData: {},
          imageUrl: {},
        });
        break;
      default:
        break;
    }
  };

  //for image name displayed in image div after image selected
  const getDisplayName = (imageData) => {
    //check if image is captured or uploaded
    if (imageData.uid) {
      if (imageData && imageData.name.length > 20) {
        return `${imageData.name.substring(0, 18)}...${imageData.type.substring(
          6
        )}`;
      }
    }
    return imageData?.name;
  };

  // useEffect(()=>{
  //   deleteImgInLS()
  // },[])

  const deleteImgInLS = (e) => {
    // Get current formData from localStorage
    const storedData = JSON.parse(localStorage.getItem("BasicForm"));

    // Remove the 'profile' property
    delete storedData?.profile;

    // Save the updated data back to localStorage
    localStorage.setItem("BasicForm", JSON.stringify(storedData));
    setIsNotImg(true);
  };

  return (
    <>
      <Form
        className={title === "basic-form" ? "mt-3" : "modal-form"}
        name="basic"
        onFinish={(values) => {
          onFinish(
            values,
            basicForm,
            profileImage.imageData,
            insuranceImage.imageData,
            cglImage.imageData,
            vehicleImage.imageData
          );
          // onFinish(values, profileImage);
        }}
        autoComplete="off"
        key={title}
      >
        {userData?.formStatus === 0 ||
        isAdmin === "Super Admin" ||
        isAdmin === "Admin" ? (
          <Row>
            {inputFields.map((item) =>
              item.isblank ? (
                <Col
                  key={item.name}
                  span={isMobile || item?.isFullspan ? 24 : 12}
                ></Col>
              ) : (
                <Col
                  key={item.name}
                  span={isMobile || item?.isFullspan ? 24 : 12}
                >
                  {/* // <Collapse in={item.name === "workforceType" ? showDropdown : true}> */}
                  {item.name === "contactName1" && isMobile && (
                    <div className="form-heading my-4">Emergency Contact</div>
                  )}

                  {item.name === "startDate" && (
                    <div className="form-heading my-4">
                      Insurance Information
                    </div>
                  )}
                  {item.name === "make" && (
                    <div className="form-heading my-4">Vehicle Information</div>
                  )}
                  {item.name === "accountHolder" && (
                    <div className="form-heading my-4">
                      Banking information (Required)
                    </div>
                  )}
                  {item.name === "accountHolderOpt" && (
                    <div className="form-heading my-4">
                      Banking information (Optional)
                    </div>
                  )}
                  {item.name === "profile" && (
                    <div className="form-heading my-4">Profile Image</div>
                  )}

                  <Form.Item
                    style={{ fontFamily: "Poppins" }}
                    className={
                      item.name === "contracterType" &&
                      selected !== "Contractor"
                        ? "visually-hidden"
                        : ""
                    }
                    labelCol={
                      title === "basic-form" && !item?.isFullspan
                        ? {
                            span: 9,
                          }
                        : item?.isFullspan
                        ? {
                            span: 10,
                          }
                        : {
                            span: 8,
                          }
                    }
                    wrapperCol={
                      title === "basic-form" && !item?.isFullspan
                        ? {
                            span: 14,
                          }
                        : item?.isFullspan
                        ? {
                            span: 8,
                          }
                        : {
                            span: 27,
                          }
                    }
                    key={item.name}
                    label={item.label}
                    name={[item.name]}
                    // initialValue={isDataInLS ? basicForm[item.name] || "" : ""}
                    initialValue={
                      item.label !== "Start Date" &&
                      item.label !== "Expire Date" &&
                      item.name !== "vehicleRegisterDate"
                        ? isDataInLS
                          ? basicForm[item.name]
                          : ""
                        : undefined
                    }
                    rules={
                      item.name === "profile" ||
                      item.name === "insuranceImage" ||
                      item.name === "vehicleImage" ||
                      item.name === "cglinsuranceImage"
                        ? [{ required: false }]
                        : [
                            item.label === "Start Date" ||
                            item.label === "Expire Date" ||
                            item.name === "vehicleRegisterDate"
                              ? {
                                  type: "object",
                                  required: false,
                                  message: `Please select ${item.label}!`,
                                }
                              : {
                                  required:
                                    selected === "Contractor"
                                      ? true
                                      : [
                                          "contracterType",
                                          "accountHolderOpt",
                                          "bankNameOpt",
                                          "routingOpt",
                                          "confirmRoutingOpt",
                                          "accountOpt",
                                          "confirmAccountOpt",
                                        ].includes(item.name)
                                      ? false
                                      : false,
                                  type: item.name === "email" ? item.name : "",
                                  message:
                                    item.name === "email"
                                      ? `Invalid Email Format`
                                      : `Enter your ${item.label}!`,
                                },
                            {
                              validator: (_, value) => {
                                // New validation rule for specific number fields
                                if (
                                  numberFields.includes(item.name) &&
                                  value &&
                                  isNaN(Number(value))
                                ) {
                                  return Promise.reject(
                                    `Enter only numeric value`
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]
                    }
                  >
                    {item.name === `password` ? (
                      <Input.Password
                        placeholder={`Enter your ${item.label}`}
                      />
                    ) : item.name === "role" ? (
                      <Radio.Group>
                        <Radio.Button value="Admin">Admin</Radio.Button>
                        {/* <Radio.Button value="User">User</Radio.Button> */}
                      </Radio.Group>
                    ) : item.name === "empRole" ? (
                      <Radio.Group>
                        <Radio.Button value="Employee">Employee</Radio.Button>
                      </Radio.Group>
                    ) : item.name === "compRole" ? (
                      <Radio.Group>
                        <Radio.Button value="Contractor Company">
                          Company
                        </Radio.Button>
                      </Radio.Group>
                    ) : item.name === "indRole" ? (
                      <Radio.Group>
                        <Radio.Button value="Contractor Individual">
                          Individual
                        </Radio.Button>
                      </Radio.Group>
                    ) : // :
                    (item.name === "fName" && isGetName) ||
                      (item.name === "lName" && isGetName) ? (
                      <Input />
                    ) : // : item.isblank ? (
                    //   ""
                    // )
                    item.label === "Start Date" ||
                      item.label === "Expire Date" ||
                      item.name === "vehicleRegisterDate" ? (
                      <DatePicker
                        className="date-select w-100"
                        placeholder={`Select ${item.label}`}
                        defaultValue={
                          isDataInLS ? moment(basicForm[item.name]) : null
                        }
                      />
                    ) : item.name === "profile" ||
                      item.name === "insuranceImage" ||
                      item.name === "vehicleImage" ||
                      item.name === "cglinsuranceImage" ? (
                      fieldNames.includes(item.name) &&
                      (profileImage.imageData ||
                        insuranceImage.imageData ||
                        cglImage.imageData ||
                        vehicleImage.imageData) ? (
                        // ||
                        // (!isNotImg && isDataInLS)
                        <Row className={"image-div"}>
                          {/* {isDataInLS ? (
                        <Col
                          className="upload-col"
                          span={20}
                          onClick={showModal}
                        >
                          {basicForm?.profile?.file.name.length > 15
                            ? `${basicForm.profile.file.name.substring(
                                0,
                                12
                              )}.${basicForm.profile.file.type.substring(
                                6,
                                10
                              )}`
                            : basicForm.profile.file.name}
                        </Col>
                      ) : ( */}
                          <Col
                            className="upload-col"
                            span={20}
                            onClick={() => showModal(item.name)}
                            style={{ cursor: "pointer" }}
                          >
                            {item.name === "profile"
                              ? getDisplayName(profileImage.imageData)
                              : item.name === "insuranceImage"
                              ? getDisplayName(insuranceImage.imageData)
                              : item.name === "cglinsuranceImage"
                              ? getDisplayName(cglImage.imageData)
                              : getDisplayName(vehicleImage.imageData)}
                          </Col>
                          {/* // )} */}
                          <Col
                            span={4}
                            // onClick={isDataInLS ? deleteImgInLS : deleteImg}
                            onClick={() => deleteImg(item.name)}
                            className="delete-icon"
                          >
                            <DeleteOutlineIcon sx={{ fontSize: "16px" }} />
                          </Col>
                        </Row>
                      ) : (
                        <>
                          {/* check if click on capture button */}
                          {!fieldNamesCapture.includes(item.name) ? (
                            <Row>
                              <Col
                                xs={11}
                                sm={24}
                                md={24}
                                xl={11}
                                className="image-center"
                              >
                                <Upload {...uploadProps(item.name)}>
                                  <Row className="upload-div">
                                    <Col className="upload-col" span={20}>
                                      Upload Photo
                                    </Col>
                                    <Col className="upload-col" span={4}>
                                      <UploadOutlined />
                                    </Col>
                                  </Row>
                                </Upload>
                              </Col>
                              <Col
                                xs={2}
                                sm={24}
                                md={24}
                                xl={2}
                                className="image-center"
                              >
                                OR
                              </Col>
                              <Col
                                xs={11}
                                sm={24}
                                md={24}
                                xl={11}
                                className="image-center"
                              >
                                <Row
                                  className="upload-div"
                                  onClick={() => startCamera(item.name)}
                                  style={{ width: "130px", cursor: "pointer" }}
                                >
                                  <Col className="upload-col" span={20}>
                                    Capture Photo
                                  </Col>
                                  <Col className="upload-col" span={4}>
                                    <CameraOutlined />
                                  </Col>
                                </Row>
                                {/* </Upload> */}
                              </Col>
                            </Row>
                          ) : (
                            <div>
                              <Webcam
                                height={500}
                                width={400}
                                className="img-fluid"
                                // ref={videoRef}
                                // screenshotFormat="image/jpeg"
                                // screenshotQuality={0.8}
                              />
                              <video
                                ref={videoRef}
                                width="320"
                                height="240"
                                autoPlay
                                style={{ display: "none" }}
                              />
                              <div className="d-flex justify-content-between">
                                <Button
                                  type="primary"
                                  onClick={() => captureImage(item.name)}
                                >
                                  Capture Image
                                </Button>
                                <Button
                                  type="primary"
                                  danger
                                  onClick={() => offCamera(item.name)}
                                >
                                  Rmove
                                </Button>
                              </div>
                              <canvas
                                ref={canvasRef}
                                width="320"
                                height="240"
                                style={{ display: "none" }}
                              />

                              {/* {image && (
                              <div>
                                <p>File Name: {image.name}</p>
                                <p>
                                  File Size: {(image.size / 1024).toFixed(2)} KB
                                </p>
                                <img
                                  src={URL.createObjectURL(image)}
                                  alt="Captured"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            )} */}
                            </div>
                          )}
                        </>
                      )
                    ) : (
                      // <input type="file" onChange={(e) => setImage(e.target.files[0])}/>

                      <Input
                        disabled={userData?.formStatus === 1}
                        placeholder={`Enter ${item.label}`}
                      />
                    )}
                  </Form.Item>
                  {/* // </Collapse> */}
                </Col>
              )
            )}
          </Row>
        ) : (
          <Form
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 18,
            }}
          >
            <div className="row justify-content-around">
              {/* <div className="  col-lg-5 col-md-6 col-sm-12 text-center "> */}
              {/* <h5 className="mb-4" style={{ color: "#1677FF" }}>
                Personal Information
              </h5> */}
              {/* <div className="pe-3"> */}
              {displaySubmittedData.map((item) => {
                return (
                  <>
                    {/* to make allignment  */}
                    {item.isblank && item.access.includes(isAdmin) ? (
                      <div
                        key={item.name}
                        className="col-lg-5 col-md-6 col-sm-12 text-center"
                      ></div>
                    ) : //render only those field which are allowed for them
                    item.input !== null && item.access.includes(isAdmin) ? (
                      <div
                        key={item.name}
                        className={
                          (item.name === "profile" && !isMobile) ||
                          item.isOpt === false
                            ? "col-10 text-center"
                            : "col-lg-5 col-md-6 col-sm-12 text-center"
                        }
                      >
                        {item.name === "contactName1" && isMobile && (
                          <div className="form-heading my-4">
                            Emergency Contact
                          </div>
                        )}

                        {item.name === "startDate" && (
                          <div className="form-heading my-4">
                            Insurance Information
                          </div>
                        )}
                        {item.name === "make" && (
                          <div className="form-heading my-4">
                            Vehicle Information
                          </div>
                        )}
                        {item.name === "accountHolder" && (
                          <div className="form-heading my-4">
                            Banking information (Required)
                          </div>
                        )}
                        {item.name === "accountHolderOpt" && (
                          <div className="form-heading my-4">
                            Banking information (Optional)
                          </div>
                        )}
                        {item.name === "profile" && (
                          <div className="form-heading my-4">Profile Image</div>
                        )}

                        <div className>
                          <Form.Item label={item.label} name={item.name}>
                            <div
                              style={
                                item.label.includes("Picture")
                                  ? {
                                      minHeight: "160px",
                                    }
                                  : {
                                      height: "30px",
                                      background: "#80808040",
                                      border: "  1px solid #80808040  ",
                                    }
                              }
                              className=" text-left  p-1 rounded"
                            >
                              {" "}
                              {item.input}
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}
              {/* </div> */}
              {/* </div> */}
            </div>
          </Form>
        )}
        <Form.Item className="d-flex justify-content-end">
          <Buttons
            // disabled until all images are selected
            disabled={
              title === "basic-form" && location.pathname !== "/Employee-Form"
                ? !(
                    profileImage.imageUrl &&
                    insuranceImage.imageUrl &&
                    cglImage.imageUrl &&
                    vehicleImage.imageUrl
                  )
                : title === "basic-form" &&
                  location.pathname === "/Employee-Form"
                ? !profileImage.imageUrl
                : false
            }
            className={"mt-2"}
            type={"primary"}
            title={title === "basic-form" ? "Next" : "Submit"}
            htmlType={"submit"}
          />
          {/* <Button type="primary" htmlType="submit">
              Submit
            </Button> */}
        </Form.Item>
      </Form>
      <Modal
        visible={imageModal.isImage}
        onCancel={() => setImageModal({ isImage: false, imageField: null })}
        footer={null}
        className="md"
      >
        <img
          src={
            imageModal.imageField == "profile"
              ? profileImage.imageUrl
              : imageModal.imageField == "insuranceImage"
              ? insuranceImage.imageUrl
              : imageModal.imageField == "cglinsuranceImage"
              ? cglImage.imageUrl
              : vehicleImage.imageUrl
          }
          alt="Captured"
          style={{
            maxWidth: "100%",
            minWidth: "100%",
            minHeight: "200px",
            marginTop: "30px",
          }}
        />
      </Modal>
    </>
  );
};

export default Forms;
