import { configureStore } from "@reduxjs/toolkit";
import LoginReducer from "./Reducers/LoginReducer";
import AdminReducer from "./Reducers/AdminReducer";
import signupReducer from "./Reducers/signupReducer";
import accountsFind from "./Reducers/accountsFind";
import employeeWorkforceReducer from "./Reducers/employeeWorkforceReducer";
import companyWorforceReducer from "./Reducers/companyWorforceReducer";
import individualWorkforceReducer from "./Reducers/individualWorkforceReducer";
import employeeDataReducer from "./Reducers/employeeDataReducer";
import rolesReducer from "./Reducers/rolesReducer";
import officeLocationReducer from "./Reducers/officeLocationReducer";

export const store = configureStore({
  reducer: {
    login: LoginReducer,
    admin: AdminReducer,
    workforce: employeeWorkforceReducer,
    companyWorkforce: companyWorforceReducer,
    individualWorkforce: individualWorkforceReducer,
    signup: signupReducer,
    employeeUser: employeeDataReducer ,
    multipleAccountsLogin: accountsFind,
    roles : rolesReducer,
    location: officeLocationReducer
  },
});
