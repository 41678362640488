import { Button, Form, Input, Select, Typography } from "antd";
import classes from "./auth-form.module.css";
import logo from "../../Asset/Images/UGALogo.png";
import { useDispatch } from "react-redux";
import { sendloginCred } from "../../Store/ApiActions/loginApi";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { signUp } from "../../Store/ApiActions/signupApi";
import { getRole } from "../../Store/ApiActions/accountsFindApi";

const { Title } = Typography;
const { Option } = Select;

const inputField = [
  {
    name: "username",
    label: "Name",
    render: (isMember) => isMember,
  },
  {
    name: "email",
    label: "Email",
    render: () => true,
  },
  {
    name: "role",
    label: " Select Role",
    render: () => true,
  },
  {
    name: "password",
    label: "Password",
    render: () => true,
  },
];

const Login = () => {
  const dispatch = useDispatch();
  const [isMember, setIsMember] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [role, setRole] = useState(null);
  const [unEditibleEmail, setUnEditibleEmail] = useState("");
  console.log(unEditibleEmail);

  const onEmailFinish = async (values) => {
    console.log(values);
    setUnEditibleEmail(values.email);

    try {
      setLoader(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const response = await dispatch(getRole(values));
      console.log(response);
      if (response.payload.status === true) {
        setRole(response.payload.data);
        toast.success(response.payload.message);
        setLoader(false);
        setShowEmail(false);
      } else {
        toast.error(response.payload.message);
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogin = () => {
    setIsMember(true);
  };
  const handleSignup = () => {
    setIsMember(false);
  };
  const [form] = Form.useForm();
  // var role = "Admin";
  const onFinish = async (values) => {
    console.log("login value", values);

    const loginValues = {
      ...values,
      email: unEditibleEmail,
    };

    const { email, password, username, role } = loginValues;
    console.log(email, password, username, role);
    if ((email === "" && password === "") || (isMember && username === "")) {
      return;
    }

    try {
      if (isMember) {
        const res = await dispatch(signUp(values));
        console.log(res);
        if (res.payload.status === true) {
          toast.success("Account Created Successfully");
          setIsMember(false);
          form.resetFields();
        }
      } else {
        setLoader(true);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const result = await dispatch(sendloginCred({ email, password, role }));
        console.log(result);
        if (result.payload.status === true) {
          toast.success(result.payload.message);
          const getToken = result.payload.data.token;
          localStorage.setItem("token", getToken);
          form.resetFields();
        } else {
          toast.error(result.payload.message);
          setLoader(false);
        }
      }
    } catch (err) {}
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.loginBox} `}>
        <div className="text-center">
          <img
            src={logo}
            style={{ filter: "contrast(0.5)" }}
            alt="logo"
            height={"100px"}
            width={"150px"}
          />
        </div>
        <Title
          level={2}
          className="mt-2 text-center"
          style={{ fontFamily: "Poppins", color: "#4096FF" }}
        >
          {isMember ? "Sign Up" : "Sign In"}
        </Title>

        {showEmail && !isMember ? (
          <div>
            <Form
              className={classes.loginForm}
              name="basic"
              onFinish={onEmailFinish}
              autoComplete="off"
            >
              <Form.Item
                style={{ fontFamily: "Poppins" }}
                name="email"
                labelCol={{
                  span: 5,
                }}
                wrapperCol={{
                  span: 27,
                }}
                rules={[
                  {
                    type: "email",
                    message: `Invalid Email Format`,
                  },
                ]}
              >
                <Input size="large" placeholder={`Enter your Email`} />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  className=""
                >
                  {loader ? (
                    <div>
                      Pease wait...
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 18,
                              color: "white",
                            }}
                            spin
                          />
                        }
                      />{" "}
                    </div>
                  ) : (
                    "Next"
                  )}
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <Form
            className={classes.loginForm}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
          >
            {inputField.map(
              (item) =>
                // Check the render function to conditionally render the input field
                item.render(isMember) &&
                (isMember && item.name === "role" ? null : (
                  <Form.Item
                    style={{ fontFamily: "Poppins" }}
                    labelCol={{
                      span:
                        item.name === "email" || item.name === "username"
                          ? 5
                          : 9,
                    }}
                    wrapperCol={{
                      span: 27,
                    }}
                    key={item.name}
                    label={isMember && item.name === "role" ? "" : item.label}
                    name={[item.name]}
                    rules={[
                      {
                        required: !isMember && item.name !== "email", // Set required to true for fields other than email
                        type: item.name === "email" ? item.name : "",
                        message:
                          item.name === "email"
                            ? `Invalid Email Format`
                            : `Enter your ${item.label}!`,
                      },
                    ]}
                  >
                    {item.name === "password" ? (
                      <Input.Password
                        size="large"
                        placeholder={`Enter your ${item.label}`}
                      />
                    ) : item.name === "role" && !isMember ? (
                      <Select placeholder={` ${item.label}`}>
                        {role &&
                          role.map((item) => (
                            <Option key={item.role} value={item.role}>
                              {item.role}
                            </Option>
                          ))}
                      </Select>
                    ) : item.name === "email" ? (
                      <Input
                        defaultValue={unEditibleEmail}
                        disabled={!isMember}
                        size="large"
                        placeholder={`Enter your ${item.label}`}
                      />
                    ) : (
                      <Input
                        size="large"
                        placeholder={`Enter your ${item.label}`}
                      />
                    )}
                  </Form.Item>
                ))
            )}

            <Form.Item
              wrapperCol={{
                span: 27,
              }}
            >
              {isMember ? (
                <Button type="primary" htmlType="submit" block size="large">
                  {loader ? (
                    <div>
                      Pease wait...
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 18,
                              color: "white",
                            }}
                            spin
                          />
                        }
                      />{" "}
                    </div>
                  ) : (
                    "Signup"
                  )}
                </Button>
              ) : (
                <Button type="primary" htmlType="submit" block size="large">
                  {loader ? (
                    <div>
                      Pease wait...
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 18,
                              color: "white",
                            }}
                            spin
                          />
                        }
                      />{" "}
                    </div>
                  ) : (
                    "Login"
                  )}
                </Button>
              )}
            </Form.Item>
          </Form>
        )}

        {isMember ? (
          <div className="text-center">
            Already Member? <Link onClick={handleSignup}>Login</Link>
          </div>
        ) : (
          <div className="text-center">
            Not a member Yet? <Link onClick={handleLogin}>Signup</Link>
          </div>
        )}
      </div>
    </div>
  );
};
export default Login;
