import { createSlice } from "@reduxjs/toolkit";
import { signUp } from "../ApiActions/signupApi";

const initialState = {
  signup: [],
  isFecthing: false,
  error: "",
};

const signUpData = createSlice({
  name: "signup",
  initialState,
  reducers: {},
  extraReducers: (obj) => {
    //Create
    obj.addCase(signUp.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(signUp.fulfilled, (state, action) => {
      console.log(action);
      state.signup = action.payload;
      state.isFecthing = false;
    });
    obj.addCase(signUp.rejected, (state, action) => {
      state.isFecthing = false;
      state.signup = [];
      state.error = action.error;
    });
  },
});

export default signUpData.reducer;
