import { createSlice } from "@reduxjs/toolkit";
import { getRole } from "../ApiActions/accountsFindApi";

const initialState = {
  getAccountsData : [],
};

const multipleAccountsLogin = createSlice({
  name: "multipleAccounts",
  initialState,
  reducers: {},
  extraReducers: (obj) => {
    obj.addCase(getRole.pending, (state) => {
       state.isFecthing =  true;
    });
    obj.addCase(getRole.fulfilled, (state, action) => {
      console.log(action)
      state.getAccountsData = action.payload;
    });
    obj.addCase(getRole.rejected, (state, action) => {
      state.getAccountsData = [];
    });
  },
});

export default multipleAccountsLogin.reducer;
