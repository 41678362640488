import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../ApiRoute/ApiRoute";

export const createAdmin = createAsyncThunk(
  //action type string
  "admin/createAdmin",
  //callback func
  async (data, { rejectWithValue }) => {
    // console.log(data , rejectWithValue)
    try {
      const response = await userRequest.post("/insertAdmin", data);
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue("error occured while Creating");
    }
  }
);
export const getAdmin = createAsyncThunk(
  //action type string
  "admin/getAdmin",
  //callback func
  async (data, { rejectWithValue }) => {
    try {
      const data1 = (await userRequest.get("/selectAdmin")).data.data
      // console.log(data1);
      return (await userRequest.get("/selectAdmin")).data.data;
    } catch (err) {
      return rejectWithValue("error occured while fetching");
    }
  }
);
export const updateAdmin = createAsyncThunk(
  // action type string
  "admin/updateAdmins",
  //  callback func
  async (data, { rejectWithValue }) => {
    try {
      return await userRequest.post("/updateAdmin", data);
    } catch (err) {
      return rejectWithValue("error occuredin while updating");
    }
  }
);
export const deleteAdmin = createAsyncThunk(
  // action type string
  "admin/deleteAdmins",
  //  callback func
  async (data, { rejectWithValue }) => {
    try {
      return await userRequest.post("/deleteAdmin", data);
    } catch (err) {
      return rejectWithValue("error occured while deleting");
    }
  }
);