import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../ApiRoute/ApiRoute";

export const createCompanyWorkforce = createAsyncThunk(
  //action type string
  "companyWorkforce/createCompanyWorkforce",
  //callback func
  async (data, { rejectWithValue }) => {
    try {
      const response = await userRequest.post("/insertContractorCompany", data);
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue("error occured while Creating");
    }
  }
);
export const getCompanyWorkforce = createAsyncThunk(
  //action type string
  "companyWorkforce/getCompanyWorkforce",
  //callback func
  async (data, { rejectWithValue }) => {
    try {
      let res = await userRequest.get("/selectContractorCompany");
      console.log("response>>>",res);
      return res.data.data;
    } catch (err) {
      return rejectWithValue("error occured while fetching");
    }
  }
);
export const updateCompanyWorkforce = createAsyncThunk(
  // action type string
  "companyWorkforce/updateCompanyWorkforce",
  //  callback func
  async (data, { rejectWithValue }) => {
    try {
      return await userRequest.post("/updateContractorCompanyFormAdmin", data);
    } catch (err) {
      return rejectWithValue("error occured while updating");
    }
  }
);
