import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modals from "../../Components/Modal/Modals";
import { toast } from "react-toastify";
import {
  createAdmin,
  deleteAdmin,
  updateAdmin,
} from "../../Store/ApiActions/AdminApi";
import { useDispatch, useSelector } from "react-redux";
import { getAdmin } from "../../Store/ApiActions/AdminApi";
import Tables from "../../Components/Tables/Tables";
import Buttons from "../../Components/Button/Buttons";
import { adminInputFields as inputFields } from "../../Utils/dataUtils";
import { getAllRoles } from "../../Store/ApiActions/rolesApi";

const Admin = () => {
  const [isloading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [userRoles, setUserRoles] = useState([]);

  const dispatch = useDispatch();
  const allAdminData = useSelector((state) => state.admin?.getAdminData);
  console.log("Users Data>>>", allAdminData);
  const allRoles = useSelector((state) => state.roles?.getRoles);

  console.log("Roles Data>>>", allRoles);

  const multipleRoles = () => {
    let usersWithRoles = allAdminData.map((user) => {
      // Filter roles for the current user based on userId
      let allUsersRoles = userRoles
        ?.filter((role) => role.userId === user.userId)
        ?.map((role) => role.role); // Extract only the role names
      return {
        ...user,
        role1: allUsersRoles[0],
        role2: allUsersRoles[1],
        role3: allUsersRoles[3],
      };
    });
    console.log(usersWithRoles);
    setTableData(usersWithRoles);
  };

  // useEffect(() => {
  //   if (allAdminData) {
  //     setTableData(allAdminData);
  //   }
  // }, [allAdminData]);

  useEffect(() => {
    if (allAdminData) {
      setUserRoles(allRoles);
    }
  }, [allAdminData]);

  useEffect(() => {
    multipleRoles();
  }, [allAdminData, userRoles]);

  useEffect(() => {
    dispatch(getAdmin());
    dispatch(getAllRoles());
  }, [dispatch]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    console.log("checked2");
    // form.resetFields();
    setIsModalOpen(false);
  };
  const handleSubmit = async (values) => {
    console.log("Modal Data >>>", values);
    setIsLoading(true);
    const response = await dispatch(createAdmin(values));
    console.log(response);
    if (response.payload.status) {
      dispatch(getAdmin());
      setTimeout(() => {
        setIsLoading(false);
        setIsModalOpen(false);
        toast.success("Added Succesfuly");
      }, 2000);
    }
    else {
      setIsLoading(false);
      setIsModalOpen(false);
      toast.warning(response.payload.message);
    }
    // form.resetFields();
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    values = { ...values, id: row.original.id };

    if (!Object.keys(validationErrors).length) {
      exitEditingMode(); //required to exit editing mode and close modal
      const updatedResult = await dispatch(updateAdmin(values));
      console.log(updatedResult);

      if (updatedResult.payload.status === 200) {
        dispatch(getAdmin());
        toast.success("Updated Succesfuly");
      }
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("username")}`
        )
      ) {
        return;
      }
      const deletedData = await dispatch(deleteAdmin(row.original));
      console.log(deletedData);
      if (deletedData.payload.status) {
        dispatch(getAdmin());
        toast.success("Deleted Succesfuly");
      }
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          setValidationErrors({
            ...validationErrors,
            // [cell.id]: `${cell.column.columnDef.header} is required`,
          });
        },
      };
    },
    [validationErrors]
  );
  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "id",
      //   header: "Id",
      //   enableEditing: false, //disable editing on this column
      //   size: 50,
      // },
      {
        accessorKey: "username",
        header: "User Name",
        size: 50,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 50,
        enableEditing: false,
      },
      // {
      //   accessorKey: "password",
      //   header: "Password",
      //   size: 50,
      // },
      {
        accessorKey: "role1",
        header: "Role 1",
        size: 50,
        enableEditing: false,

      },
      {
        accessorKey: "role2",
        header: "Role 2",
        size: 50,
        enableEditing: false,

      },
      {
        accessorKey: "role3",
        header: "Role 3",
        size: 50,
        enableEditing: false,

      },
    ],
    [getCommonEditTextFieldProps, tableData]
  );
  return (
    <div>
      <Buttons
        title={"Create Admin"}
        className={"mt-2 mx-3 btn-ui"}
        // type={"primary"}
        onClick={handleModalOpen}
      />
      <Modals
        isModalOpen={isModalOpen}
        handleCancel={handleModalClose}
        onFinish={handleSubmit}
        inputFields={inputFields}
        isloading={isloading}
        // form={form}
        title={"Create Admin"}
      />

      <Tables
        columns={columns}
        tableData={tableData}
        handleDeleteRow={handleDeleteRow}
        handleSaveRowEdits={handleSaveRowEdits}
        handleCancelRowEdits={handleCancelRowEdits}
        title={"All Users"}
      />
    </div>
  );
};

export default Admin;
