import React, { useEffect, useState } from "react";
import {
  ScheduleFilled,
  BankFilled,
  SaveFilled,
  CodepenSquareFilled,
  UsergroupAddOutlined,
  GroupOutlined,
  ControlOutlined,
  ReconciliationOutlined,
  SplitCellsOutlined,
  BarsOutlined,
  AuditOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
} from "@ant-design/icons";
import LogoutIcon from "@mui/icons-material/Logout";
import { Layout, Menu, theme } from "antd";
import classes from "./Layouts.module.css";
import Admin from "../../Pages/Admin/Admin";
import Workforce from "../../Pages/Workforce/Workforce";
import Buttons from "../Button/Buttons";
import { jwtDecode } from "jwt-decode";
import picture from "../../Asset/Images/UGALogo.png";

import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import WorkforcePortal from "../../Pages/WorkforcePortal/WorkforcePortal";
import { toast } from "react-toastify";
import {
  Avatar,
  Badge,
  Box,
  Fade,
  Modal,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { logoutSuccess } from "../../Store/ApiActions/loginApi";
import { useDispatch, useSelector } from "react-redux";
import SignUp from "../Auth-forms/SignUp";
import Home from "../../Pages/Home/Home";
import { getWorkforce } from "../../Store/ApiActions/employeeWorkforceApi";
import UserHistory from "../../Pages/UserHistory/UserHistory";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import EmployeeWorkforce from "../../Pages/EmployeeWorkforce/EmployeWorkforce";
import CompanyWorkforce from "../../Pages/CompanyWorkforce/CompanyWorkforce";
import IndividuakWorkforce from "../../Pages/IndividualWorkforce/IndividuakWorkforce";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { useAdminContext } from "../../Store/ContextApi/AdminProvider";
import OfficesLocation from "../../Pages/OfficesLocation/OfficesLocation";
import { getCompanyWorkforce } from "../../Store/ApiActions/companyWorkforceApi";
import { getIndividualWorkforce } from "../../Store/ApiActions/individualWorkforceApi";

const items = [
  {
    key: "1",
    label: "Home",
    icon: <BankFilled />,
    url: "/",
    access: ["Admin", "Super Admin"],
  },
  {
    key: "2",
    label: "All Users",
    icon: <CodepenSquareFilled />,
    url: "/Users",
    access: ["Admin", "Super Admin"],
  },
  {
    key: "sub1",
    label: "Work Force",
    icon: <ScheduleFilled />,
    url: "/workforce",
    children: [
      {
        key: "5",
        label: "Employee Details",
        url: "Employee-Workforce",
        icon: <UsergroupAddOutlined />,
        access: ["Admin", "Super Admin"],
      },
      {
        key: "6",
        label: "Company Details",
        url: "Contractor-Company-Workforce",
        icon: <GroupOutlined />,
        access: ["Admin", "Super Admin"],
      },
      {
        key: "7",
        label: "Individual Details",
        url: "Contractor-Individual-Workforce",
        icon: <ControlOutlined />,
        access: ["Admin", "Super Admin"],
      },
    ],
    access: ["Admin", "Super Admin"],
  },
  // {
  //   key: "4",
  //   label: "Workforce",
  //   url: "/workforce",
  //   icon: <AuditOutlined />,
  //   access: ["Contractor Individual", "Contractor Company"],
  // },
  {
    key: "5",
    label: "Status",
    url: "/history",
    icon: <AuditOutlined />,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    key: "sub2",
    label: "Forms",
    icon: <SaveFilled />,
    url: "/form",
    children: [
      {
        key: "9",
        label: "Employee Form",
        url: "Employee-Form",
        icon: <BarsOutlined />,
        access: ["Super Admin", "Admin", "Employee"],
      },
      {
        key: "10",
        label: "Company Form",
        icon: <ReconciliationOutlined />,
        url: "Contractor-Company-Form",
        access: ["Super Admin", "Admin", "Contractor Company"],
      },
      {
        key: "11",
        label: "Individual Form",
        url: "Contractor-Individual-Form",
        icon: <SplitCellsOutlined />,
        access: ["Super Admin", "Admin", "Contractor Individual"],
      },
    ],
    access: [
      "Admin",
      "Super Admin",
      "Employee",
      "Contractor Company",
      "Contractor Individual",
    ],
  },
  {
    key: "12",
    label: "Offices",
    url: "Admin-Offices-Location",
    icon: <AuditOutlined />,
    access: ["Super Admin"],
  },
];

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },
  },
}));

const { Header, Sider, Content } = Layout;
const Layouts = ({ user }) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [value, setValue] = useState({ collapsed: true, activeContent: "1" });
  const [modalOpen, setModalOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [userImage, setUserImages] = useState("");
  const [userName, setUserName] = useState("");
  const { isAdmin, setIsAdmin } = useAdminContext();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    if (isMobile) {
      setValue((prev) => ({ ...prev, collapsed: true }));
    }
  }, [isMobile]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  var decoded;

  function decoder() {
    var token = localStorage.getItem("token");
    decoded = jwtDecode(token);
    console.log("decoded ==>", decoded);

    if (decoded == null) {
      navigate("/");
    } else {
      localStorage.setItem("username", decoded.tokenData.username);
      localStorage.setItem("userId", decoded.tokenData.id);
      localStorage.setItem("userEmail", decoded.tokenData.email);
      var user = localStorage.getItem("username");
      setIsAdmin(decoded.tokenData.role);
      return user;
    }
  }

  React.useEffect(() => {
    decoder();
  }, []);

  const logoutHandle = async ({ user }) => {
    setLoader(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    dispatch(logoutSuccess());
    setLoader(false);
    if (localStorage?.getItem("token")) {
      console.log("here");
    } else {
      toast.success("Logging Out");
      navigate("/");
      setTimeout(() => {
        window.location.reload(true);
      }, 500);
    }
  };
  let workforceDataSelector;
  if (isAdmin === "Employee") {
    workforceDataSelector = (state) => state.workforce?.getworkforceData;
  } else if (isAdmin === "Contractor Company") {
    workforceDataSelector = (state) =>
      state.companyWorkforce?.getCompnayworkforceData;
  } else {
    workforceDataSelector = (state) =>
      state.individualWorkforce?.getIndividualworkforceData;
  }

  const allWorkforceData = useSelector(workforceDataSelector);
  console.log("response2", allWorkforceData);
  const getUserData = () => {
    var loggedInUserName = localStorage.getItem("username");
    setUserName(loggedInUserName);
    var email = localStorage.getItem("userEmail");

    const activationStatus = allWorkforceData?.find(
      (item) => item.email === email
    );
    const loggedInUserStatus = activationStatus?.activeStatus;
    setUserStatus(loggedInUserStatus);
    const loggedInUserImage = activationStatus?.profile;
    setUserImages(loggedInUserImage);
  };

  useEffect(() => {
    getUserData();
  }, [allWorkforceData]);

  useEffect(() => {
    if (isAdmin === "Employee") {
      dispatch(getWorkforce());
    } else if (isAdmin === "Contractor Company") {
      dispatch(getCompanyWorkforce());
    } else {
      dispatch(getIndividualWorkforce());
    }
  }, [dispatch, isAdmin]);

  return (
    <div className={value.collapsed ? classes.layoutShrink : classes.layout}>
      <Layout hasSider>
        <Sider
          width="210px"
          trigger={null}
          collapsed={value.collapsed}
          onMouseEnter={() =>
            !isMobile &&
            setValue((prev) => ({
              ...prev,
              collapsed: false,
            }))
          }
          onMouseLeave={() =>
            !isMobile &&
            setValue((prev) => ({
              ...prev,
              collapsed: true,
            }))
          }
        >
          <div className={classes.logovertical} />
          <div className={`${classes.heading}`}>
            {value.collapsed ? (
              <div className={`${classes.ugaLogo} h5 mt-4 mx-2 p-1 `}>HRM</div>
            ) : (
              <div className={` ${classes.ugaLogo} mx-2 h2 mt-3 p-1 d-flex`}>
                <span>
                  {" "}
                  <img
                    // className={` img-fluid`}
                    style={{ maxWidth:"100%",height:"auto",filter: "dropShadow" }}
                    width={60}
                    height={50}
                    src={picture}
                    alt=""
                  />
                </span>
                <p className={` mt-1 mx-2 `}>HRM</p>
              </div>
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[location.pathname]}
            style={{
              fontSize: "15px",
              fontWeight: "400",
            }}
          >
            {items.map((item) =>
              item.children ? (
                <Menu.SubMenu
                  className="mt-4 my-icons"
                  style={
                    item.access?.includes(isAdmin)
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  key={item.key}
                  icon={item.icon}
                  title={item.label}
                >
                  {item.children.map(
                    (child) =>
                      // Check if the child item's access includes the user's role
                      child.access?.includes(isAdmin) && (
                        <Menu.Item
                          key={child.key}
                          icon={child.icon}
                          className="mt-2"
                        >
                          <Link
                            style={{ textDecoration: "none", fontSize: "13px" }}
                            to={child.url}
                          >
                            {child.label}
                          </Link>
                        </Menu.Item>
                      )
                  )}
                </Menu.SubMenu>
              ) : (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  style={
                    item.access?.includes(isAdmin)
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  className="mt-4"
                >
                  <Link style={{ textDecoration: "none" }} to={item.url}>
                    {item.label}
                  </Link>
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              color: "#969FA7",
            }}
          >
            <div className="d-flex justify-content-between mx-3">
              <div>
                <Buttons
                  type={"text"}
                  icon={
                    value.collapsed ? (
                      <DoubleRightOutlined style={{ fontSize: "18px" }} />
                    ) : (
                      <DoubleLeftOutlined style={{ fontSize: "18px" }} />
                    )
                  }
                  onClick={() =>
                    setValue((prev) => ({
                      ...prev,
                      collapsed: !value.collapsed,
                    }))
                  }
                  style={{
                    fontSize: "3.6vmin",
                    height: 64,
                    color: "white",
                  }}
                  className="mx-2"
                />
              </div>
              {[
                "Employee",
                "Contractor Company",
                "Contractor Individual",
              ].includes(isAdmin) ? (
                <>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    className={classes.badgeCircle}
                  >
                    <Avatar
                      onClick={handleModalOpen}
                      sx={
                        isMobile
                          ? { height: "40px", width: "40px" }
                          : { height: "50px", width: "50px" }
                      }
                    >
                      <img
                        src={userImage}
                        // className="img-fluid"
                        style={{ maxWidth:"100%",height:"auto" }}
                      />
                    </Avatar>
                  </StyledBadge>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modalOpen}
                    onClose={handleModalClose}
                    closeAfterTransition
                  >
                    <Fade in={modalOpen}>
                      <Box className={classes.profileModal}>
                        <Typography id="transition-modal-title">
                          <div className={classes.profileText}>
                            HEY! {userName.toUpperCase()}
                          </div>
                          {/* only employee can see this  */}
                          {isAdmin === "Employee" ? (
                            <div
                              className={classes.profileText}
                              style={{ fontSize: "2vmin" }}
                            >
                              {userStatus === 1 ? (
                                <span style={{ color: "#00bf5c" }}>
                                  You Are Active Now.
                                </span>
                              ) : userStatus === 2 ? (
                                <span style={{ color: "#9e0000" }}>
                                  Your Account Has Been Deactivated. Please
                                  contact support for assistance.
                                </span>
                              ) : (
                                <span style={{ color: "#aba100" }}>
                                  Wait for Activation.
                                </span>
                              )}
                            </div>
                          ) : null}
                        </Typography>
                        {/* <div className="text-uppercase">{userName}</div> */}
                        <Typography
                          id="transition-modal-description"
                          sx={{
                            mt: 2,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            color="inherit"
                            onClick={logoutHandle}
                            style={{ cursor: "pointer" }}
                          >
                            {loader ? (
                              <div style={{ marginRight: "20px" }}>
                                Logging out...
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      style={{
                                        fontSize: 22,
                                        color: "white",
                                        marginLeft: "10px",
                                      }}
                                      spin
                                    />
                                  }
                                />{" "}
                              </div>
                            ) : (
                              <>
                                <LogoutIcon
                                  fontSize={isMobile ? "small" : "medium"}
                                  sx={{ marginRight: 1 }}
                                />

                                <span className={classes.profileText}>
                                  Log Out
                                </span>
                              </>
                            )}
                          </div>
                        </Typography>
                      </Box>
                    </Fade>
                  </Modal>
                </>
              ) : (
                <div
                  onClick={logoutHandle}
                  style={{ cursor: "pointer" }}
                  className={isMobile ? "mx-4" : "mx-4 pe-3"}
                >
                  {loader ? (
                    <div style={{ marginRight: "20px" }}>
                      Logging out...
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 22,
                              color: "white",
                              marginLeft: "10px",
                            }}
                            spin
                          />
                        }
                      />{" "}
                    </div>
                  ) : (
                    <div>
                      Logout
                      <LogoutIcon
                        fontSize={isMobile ? "small" : "medium"}
                        sx={{ marginRight: 2, marginLeft: 1 }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Header>
          <Content
            style={{
              margin: "16px 16px",
              padding: 24,
              overflow: "hidden",
              // minHeight: 1000,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <div className="mt-3 mx-3">
              <Breadcrumbs />
            </div>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Users" element={<Admin />} />

              {/* <Route path="/workforce" element={<Workforce />} /> */}
              <Route
                path="/Employee-Workforce"
                element={<EmployeeWorkforce />}
              />
              <Route
                path="/Contractor-Company-Workforce"
                element={<CompanyWorkforce />}
              />
              <Route
                path="/Contractor-Individual-Workforce"
                element={<IndividuakWorkforce />}
              />

              <Route
                path="/form"
                element={<WorkforcePortal path={location.pathname} />}
              />
              <Route
                path="/Employee-Form"
                element={
                  <WorkforcePortal
                    path={location.pathname}
                    iscollapsed={value.collapsed}
                    // value={value}
                    setValue={setValue}
                  />
                }
              />
              <Route
                path="/Contractor-Individual-Form"
                element={
                  <WorkforcePortal
                    path={location.pathname}
                    iscollapsed={value.collapsed}
                    // value={value}
                    setValue={setValue}
                  />
                }
              />
              <Route
                path="/Contractor-Company-Form"
                element={
                  <WorkforcePortal
                    user={user}
                    path={location.pathname}
                    iscollapsed={value.collapsed}
                    // value={value}
                    setValue={setValue}
                  />
                }
              />
              <Route
                path="/Admin-Offices-Location"
                element={
                  <OfficesLocation
                    user={user}
                    path={location.pathname}
                    iscollapsed={value.collapsed}
                  />
                }
              />
              <Route path="/history" element={<UserHistory />} />
              <Route path="/signup" element={<SignUp />} />

              {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};
export default Layouts;
