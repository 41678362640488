import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../ApiRoute/ApiRoute";

export const individualUserUpdataData = createAsyncThunk(
  //action type string
  "user/userIndividualUpdateData",
  //callback func
  async (data, { rejectWithValue }) => {
    console.log(data )
    try {
      const response = await userRequest.post("/updateContractorIndividualForm", data);
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue("error occured while Creating");
    }
  }
);
