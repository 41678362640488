import React, { useEffect, useState } from "react";
import { Form } from "antd";
import {
  afterSubmitFieldsAdmin,
  afterSubmitFieldsMobileAdmin,
} from "../../Utils/dataUtils";
import { useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";

const ViewUserInfo = ({ particularUserData, rowId }) => {
  const location = useLocation();
  console.log("particularUserData ===>", particularUserData);
  const isMobile = useMediaQuery("(max-width: 750px)");
  const [displaySubmittedData, setDisplaySubmittedData] = useState([]);
  console.log("displaySubmittedData", displaySubmittedData,location.pathname);

  useEffect(() => {
    setDisplaySubmittedData(
      isMobile
        ? afterSubmitFieldsMobileAdmin(particularUserData)
        : afterSubmitFieldsAdmin(particularUserData)
    );
  }, [particularUserData]);

  return (
    <div className="mt-4">
      {particularUserData?.formStatus === 1 ? (
        <Form
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
           <div className="row justify-content-around">
            {displaySubmittedData.map((item) => {
              return (
                <>
                  {/* to make allignment  */}
                  {item.isblank && item.access.includes(location.pathname) ? (
                    <div
                      key={item.name}
                      className="col-lg-5 col-md-6 col-sm-12 text-center"
                    ></div>
                  ) : //render only those field which are allowed for them
                  item.input !== null && item.access.includes(location.pathname) ? (
                    <div
                      key={item.name}
                      className={
                        (item.name === "profile" && !isMobile) ||
                        item.isOpt === false
                          ? "col-10 text-center"
                          : "col-lg-5 col-md-6 col-sm-12 text-center"
                      }
                    >
                      {item.name === "contactName1" && isMobile && (
                        <div className="form-heading my-4">
                          Emergency Contact
                        </div>
                      )}

                      {item.name === "startDate" && (
                        <div className="form-heading my-4">
                          Insurance Information
                        </div>
                      )}
                      {item.name === "make" && (
                        <div className="form-heading my-4">
                          Vehicle Information
                        </div>
                      )}
                      {item.name === "accountHolder" && (
                        <div className="form-heading my-4">
                          Banking information (Required)
                        </div>
                      )}
                      {item.name === "accountHolderOpt" && (
                        <div className="form-heading my-4">
                          Banking information (Optional)
                        </div>
                      )}
                      {item.name === "profile" && (
                        <div className="form-heading my-4">Profile Image</div>
                      )}

                      <div className>
                        <Form.Item label={item.label} name={item.name}>
                          <div
                            style={
                              item.label.includes("Picture")
                                ? {
                                    minHeight: "160px",
                                  }
                                : {
                                    height: "30px",
                                    background: "#80808040",
                                    border: "  1px solid #80808040  ",
                                  }
                            }
                            className=" text-left  p-1 rounded"
                          >
                            {" "}
                            {item.input}
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>

        </Form>
      ) : (
        <div className="text-center mt-5">
          {" "}
          <h2 className="mt-5" style={{ color: "#4096FF" }}>
            This {particularUserData?.userRole} Form Not Filled Yet
          </h2>
        </div>
      )}
    </div>
  );
};

export default ViewUserInfo;
