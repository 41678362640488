import { createSlice } from "@reduxjs/toolkit";
import { 
  sendloginCred,
  logoutSuccess, 
} from "../ApiActions/loginApi";

const initialState = {
  getLogindata : [],
  auth: null
};

const login = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (obj) => {
    obj.addCase(sendloginCred.pending, (state) => {
       state.isFecthing =  true;
    });
    obj.addCase(sendloginCred.fulfilled, (state, action) => {
      console.log(action)
      // state.message = action.payload.message;
      state.auth = action.payload?.data.token
      state.loginCred = action.payload;
    });
    obj.addCase(sendloginCred.rejected, (state, action) => {
      state.loginCred = [];
    });
    
    obj.addCase(logoutSuccess.fulfilled , (state , action)=>{
      state.auth =  null
    })
   

  },
});

export default login.reducer;
