import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../ApiRoute/ApiRoute";

export const createIndividualWorkforce = createAsyncThunk(
  //action type string
  "individualWorkforce/createindividualWorkforce",
  //callback func
  async (data, { rejectWithValue }) => {
    try {
      const response = await userRequest.post("/insertContractorIndividual", data);
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue("error occured while Creating");
    }
  }
);
export const getIndividualWorkforce = createAsyncThunk(
  //action type string
  "individualWorkforce/getindividualWorkforce",
  //callback func
  async (data, { rejectWithValue }) => {
    try {
      let res = await userRequest.get("/selectContractorIndividual");
      console.log("response>>>",res);
      return res.data.data;
    } catch (err) {
      return rejectWithValue("error occured while fetching");
    }
  }
);
export const updateIndividualWorkforce = createAsyncThunk(
  // action type string
  "individualWorkforce/updateindividualWorkforce",
  //  callback func
  async (data, { rejectWithValue }) => {
    try {
      return await userRequest.post("/updateContractorIndividualFormAdmin", data);
    } catch (err) {
      return rejectWithValue("error occured while updating");
    }
  }
);
