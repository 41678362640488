import { Modal } from "antd";
import React, { useState } from "react";
import Loader from "../Loader/Loader";
import { Collapse } from "@mui/material";
import Forms from "../Forms/Forms";

const Modals = (props) => {
  const [selected, setSelected] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const {
    title,
    isModalOpen,
    handleCancel,
    inputFields,
    selectType,
    onFinish,
    isloading,
  } = props;
  return (
    <Modal
      title={title}
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      className={"dropdown-toggle-split m-auto footer-remove pb-5"}
      style={{ zIndex: "100px", top: "-30px" }}
      width={500}
    >
      {isloading ? (
        <Loader />
      ) : (
        <Forms
          inputFields={inputFields}
          onFinish={onFinish}
          showDropdown={showDropdown}
          selected={selected}
          selectType={selectType}
          // selectWorkforce={selectWorkforce}
          setSelected={setSelected}
          setShowDropdown={setShowDropdown}
        />
      )}
    </Modal>
  );
};

export default Modals;
