import { createSlice } from "@reduxjs/toolkit";
import { createIndividualWorkforce, getIndividualWorkforce } from "../ApiActions/individualWorkforceApi";

;

const initialState = {
    individualWorkforceData: [],
  getIndividualworkforceData: [],
  isFecthing: false,
  error: "",
};

const workforce = createSlice({
  name: "individualWorkforce",
  initialState,
  reducers: {},
  extraReducers: (obj) => {
    //Create
    obj.addCase(createIndividualWorkforce.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(createIndividualWorkforce.fulfilled, (state, action) => {
      console.log(action);

      state.workforceData = action.payload;
      state.isFecthing = false;
    });
    obj.addCase(createIndividualWorkforce.rejected, (state, action) => {
      state.isFecthing = false;
      state.individualWorkforceData = [];
      state.error = action.error;
    });

    //Get
    obj.addCase(getIndividualWorkforce.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(getIndividualWorkforce.fulfilled, (state, action) => {
      console.log("Workforce reducer>>>", action.payload);
      state.getIndividualworkforceData = action.payload;
      state.isFecthing = false;
    });
    obj.addCase(getIndividualWorkforce.rejected, (state, action) => {
      state.isFecthing = false;
      state.error = action.error;
    });
  },
});

export default workforce.reducer;
