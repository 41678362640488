import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import W4Form from "../Employee/W4Form";
import BasicForm from "../Employee/BasicForm";
import ViewUserInfo from "../ViewUserInfo/ViewUserInfo";
import { useEffect } from "react";
import { useState } from "react";
import ViewUploadFile from "../ViewUploadFile/ViewUploadFile";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export default function TabsSwipe({ rowId, allWorkforceData }) {
  const location = useLocation();
  console.log("rowId==>", rowId);
  console.log("allWorkforceData", allWorkforceData);
  const [particularUserData, setParticularUserData] = useState();

  const findingUserDataBasedOnId = () => {
    const userDataObject = allWorkforceData.find((item) => item.id === rowId);
    console.log("userData==>", userDataObject);

    setParticularUserData(userDataObject);
  };

  useEffect(() => {
    findingUserDataBasedOnId();
  }, [allWorkforceData, rowId]);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: "background.paper", width: 1400 }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          style={{ background: "#266092" }}
        >
          <Tab label={"Basic Form"} {...a11yProps(0)} />
          <Tab
            label={
              location.pathname == "/Employee-Workforce" ? "W4 Form" : "w9 Form"
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              location.pathname == "/Employee-Workforce"
                ? "Employee Handbook"
                : "Sign the Contract"
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ViewUserInfo particularUserData={particularUserData} rowId={rowId} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ViewUploadFile particularUserData={particularUserData} />
          {/* <W4Form isAdmin={true}/> */}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          Item Three
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
