import { createSlice } from "@reduxjs/toolkit";
import { userUpdataData } from "../ApiActions/employeeDataApi";



const initialState = {
  userData: [],
  isFecthing: false,
  error: "",
};

const employeeUser = createSlice({
  name: "employeeUser",
  initialState,
  reducers: {},
  extraReducers: (obj) => {
    obj.addCase(userUpdataData.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(userUpdataData.fulfilled, (state, action) => {
      // console.log("Fulfilled", action.payload);
      state.isFecthing = false;
      state.userData = action.payload;
    });
    obj.addCase(userUpdataData.rejected, (state, action) => {
      console.log("rejected");
      state.isFecthing = false;
      state.error = action.error;
    });
  },
});
export default employeeUser.reducer;