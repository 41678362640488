import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../ApiRoute/ApiRoute";

export const signUp = createAsyncThunk(
  //action type string
  "signup/createNewUser",
  //callback func
  async (data, { rejectWithValue }) => {
    console.log(data , rejectWithValue)
    try {
      const response = await userRequest.post("/signup", data);
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue("error occured while Creating");
    }
  }
);