import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../ApiRoute/ApiRoute";

export const createAdminOfficeEmailLocation = createAsyncThunk(
  //action type string
  "admin/createEmailAdminOfficeLocation",
  //callback func
  async (data, { rejectWithValue }) => {
    console.log(data )
    try {
      const response = await userRequest.post("/insertAdminLocationEmail", data);
      console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue("error occured while Creating");
    }
  }
);
export const getAdminOfficeEmailLocation = createAsyncThunk(
  //action type string
  "admin/getEmailAdminOfficeLocation",
  //callback func
  async (data, { rejectWithValue }) => {
    try {
      const res = (await userRequest.get("/selectAdminLocationEmail"))
      console.log(res)
      return res.data.data

    } catch (err) {
      return rejectWithValue("error occured while fetching");
    }
  }
);
export const updateAdminOfficeEmailLocation = createAsyncThunk(
  // action type string
  "admin/updateEmailAdminOfficeLocation",
  //  callback func
  async (data, { rejectWithValue }) => {
    try {
      return await userRequest.post("/updateAdminLocationEmail", data);
    } catch (err) {
      return rejectWithValue("error occuredin while updating");
    }
  }
);
export const deleteAdminOfficeEmailLocation = createAsyncThunk(
  // action type string
  "admin/deleteEmailAdminOfficeLocation",
  //  callback func
  async (data, { rejectWithValue }) => {
    try {
      return await userRequest.post("/deleteAdminLocationEmail", data);
    } catch (err) {
      return rejectWithValue("error occured while deleting");
    }
  }
);