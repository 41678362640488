import { Typography } from 'antd'
import React from 'react'

const Loader = () => {
  return (
    <Typography style={{marginTop:'20vh', marginBottom:'20vh'}}>
    <p className="loader"></p>
    <span className="loading-text d-flex justify-content-center"> Loading....</span>
  </Typography>
  )
}

export default Loader;