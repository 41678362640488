import { PlusCircleOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Buttons from "../../Components/Button/Buttons";
import { Button, Form, Input, Modal, Select } from "antd";
import { allOfficesLocation } from "../../Utils/dataUtils";
import Tables from "../../Components/Tables/Tables";
import { useDispatch, useSelector } from "react-redux";
import { createAdminOfficeEmailLocation, deleteAdminOfficeEmailLocation, getAdminOfficeEmailLocation, updateAdminOfficeEmailLocation } from "../../Store/ApiActions/officeLocationsApi";
import { toast } from "react-toastify";

const validateMessages = {
  required: "${label} is required!",
};

const OfficesLocation = () => {
  const getAdminsLocation = useSelector((state)=> state.location?.getOfficeLocation)
  console.log(getAdminsLocation)
  const dispatch = useDispatch()
  const [isloading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  console.log(tableData)
  const [validationErrors, setValidationErrors] = useState({});
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(()=>{
    if(getAdminsLocation.length >0){
      setTableData(getAdminsLocation)
    }
  } , [getAdminsLocation])

  useEffect(()=>{
    dispatch( getAdminOfficeEmailLocation())
  }, [dispatch])


  const onFinish = async (values) => {
    console.log(values);
    try {
      const response = await dispatch(createAdminOfficeEmailLocation(values))
      console.log(response)
      if(response.payload?.status === true){
        dispatch(getAdminOfficeEmailLocation());
        toast.success(response.payload.message)
        setIsModalOpen(false)
      }
    } catch (error) {
      
    }
  };

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          setValidationErrors({
            ...validationErrors,
          });
        },
      };
    },
    [validationErrors]
  );

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    values = { ...values, id: row.original.id };

    if (!Object.keys(validationErrors).length) {
      exitEditingMode(); //required to exit editing mode and close modal
      const updatedResult = await dispatch(updateAdminOfficeEmailLocation(values));
      console.log(updatedResult);

      if (updatedResult.payload.status === 200) {
        dispatch(getAdminOfficeEmailLocation());
        toast.success("Updated Succesfuly");
      }
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("adminEmail")}`
        )
      ) {
        return;
      }
      const deletedData = await dispatch(deleteAdminOfficeEmailLocation(row.original));
      console.log(deletedData);
      if (deletedData.payload.status) {
        toast.success("Deleted Succesfuly");
        dispatch(getAdminOfficeEmailLocation());
      }
    },
    [tableData]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "adminEmail",
        header: "Email",
        size: 150,
        enableEditing: true,
      },

      {
        accessorKey: "officeLocation",
        header: "Location",
        size: 75,
        enableEditing: true,
        editVariant: "select",
        editSelectOptions: allOfficesLocation.map((item) => ({ value: item.location, label: item.location })),

        // customEditor: {
        //   type: "select",
        //   options: allOfficesLocation.map((loc) => ({
        //     label: loc.location,
        //     value: loc.id,
        //   })),
        // },
      },
    ],
    [getCommonEditTextFieldProps, tableData]
  );
  return (
    <div>
      <Buttons
        icon={<PlusCircleOutlined style={{ fontSize: "17px" }} />}
        title={"Add Admin"}
        className={"mt-2 mx-3 btn-ui"}
        // type={"primary"}
        onClick={handleModalOpen}
      />

      <Modal
        title="Add Admins"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={500}
      >
        <Form
          className="mt-4"
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="adminEmail"
            label="Add Email"
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter Email" style={{ height: "35px" }} />
          </Form.Item>
          <Form.Item
            name="officeLocation"
            label="Office Location"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Select Location" style={{ height: "35px" }}>
              {allOfficesLocation?.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.location}>
                    {item.location}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item className="text-end">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Tables
        columns={columns}
        tableData={tableData}
        handleDeleteRow={handleDeleteRow}
        handleSaveRowEdits={handleSaveRowEdits}
        handleCancelRowEdits={handleCancelRowEdits}
        title={"Admins Offices"}
      />
    </div>
  );
};

export default OfficesLocation;
