//Workforce (Client) Fields For Basic Form Comp
export const inputFields = [
  {
    name: "fName",
    label: "First Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "contactName1",
    label: "Contact 1 Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "lName",
    label: "Last Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "contactPhone1",
    label: "Contact 1 Phone",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "ssn",
    label: "SSN #",
    type: ["emp"],
  },
  {
    name: "ein",
    label: "EIN #",
    type: ["ind", "comp"],
  },
  {
    name: "contactName2",
    label: "Contact 2 Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "phone",
    label: "Phone",
    type: ["emp", "ind"],
  },
  {
    name: "phone",
    label: "Business Phone",
    type: ["comp"],
  },
  {
    name: "contactPhone2",
    label: "Contact 2 Phone",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "address",
    label: "Address",
    type: ["emp", "ind"],
  },
  {
    name: "address",
    label: "Business Address",
    type: ["comp"],
  },
  {
    isblank: true,
    type: ["emp", "ind","comp"],
  },
  {
    name: "compName",
    label: "Company Name",
    type: ["comp"],
  },
  {
    isblank: true,
    type: ["comp"],
  },
  {
    name: "startDate",
    label: "Start Date",
    type: ["ind", "comp"],
  },
  {
    name: "make",
    label: "Make",
    type: ["ind", "comp"],
  },
  {
    name: "expireDate",
    label: "Expire Date",
    type: ["ind", "comp"],
  },
  {
    name: "model",
    label: "Model",
    type: ["ind", "comp"],
  },
  {
    name: "insuranceImage",
    label: "Insurance Picture",
    type: ["ind", "comp"],
  },
  {
    name: "year",
    label: "Year",
    type: ["ind", "comp"],
  },

  {
    name: "cglInsurance",
    label: "CGL Insurance",
    type: ["ind", "comp"],
  },
  // {
  //   isblank: true,
  //   type: ["ind"],
  // },
  {
    name: "vehicleRegisterDate",
    label: "Registration Date",
    type: ["ind", "comp"],
  },

  {
    name: "cglStartDate",
    label: "Start Date",
    type: ["ind", "comp"],
  },
  {
    name: "vehicleImage",
    label: "Vehicle Picture",
    type: ["ind", "comp"],
  },
  // {
  //   isblank: true,
  //   type: ["comp"],
  // },
  {
    name: "cglExpireDate",
    label: "Expire Date",
    type: ["ind", "comp"],
  },
  {
    isblank: true,
    type: ["ind", "comp"],
  },
  {
    name: "cglinsuranceImage",
    label: "CGL Picture",
    type: ["ind", "comp"],
  },
  {
    isblank: true,
    type: ["ind", "comp"],
  },
  {
    name: "accountHolder",
    label: "Account Holder (Name)",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "accountHolderOpt",
    label: "Account Holder (Name)",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "bankName",
    label: "Bank Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "bankNameOpt",
    label: "Bank Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "routing",
    label: "Routing #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "routingOpt",
    label: "Routing #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmRoutingOpt",
    label: "Confirm Routing #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "account",
    label: "Account #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "accountOpt",
    label: "Account #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmAccount",
    label: "Confirm Account #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmAccountOpt",
    label: "Confirm Account #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "profile",
    label: "Picture for Profile",
    isFullspan: true,
    type: ["emp", "ind", "comp"],
  },
];

export const inputFieldsForMobile = [
  {
    name: "compName",
    label: "Company Name",
    type: ["comp"],
  },
  {
    name: "fName",
    label: "First Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "lName",
    label: "Last Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "ssn",
    label: "SSN #",
    type: ["emp"],
  },
  {
    name: "ein",
    label: "EIN #",
    type: ["ind", "comp"],
  },
  {
    name: "phone",
    label: "Phone",
    type: ["emp", "ind"],
  },
  {
    name: "phone",
    label: "Business Phone",
    type: ["comp"],
  },
  {
    name: "address",
    label: "Address",
    type: ["emp", "ind"],
  },
  {
    name: "address",
    label: "Business Address",
    type: ["comp"],
  },
  {
    name: "contactName1",
    label: "Contact 1 Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "contactPhone1",
    label: "Contact 1 Phone",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "contactName2",
    label: "Contact 2 Name",
    type: ["emp", "ind", "comp"],
  },

  {
    name: "contactPhone2",
    label: "Contact 2 Phone",
    type: ["emp", "ind", "comp"],
  },

  {
    name: "make",
    label: "Make",
    type: ["ind", "comp"],
  },
  {
    name: "model",
    label: "Model",
    type: ["ind", "comp"],
  },
  {
    name: "year",
    label: "Year",
    type: ["ind", "comp"],
  },
  {
    name: "vehicleImage",
    label: "Vehicle Picture",
    type: ["ind", "comp"],
  },
  {
    name: "startDate",
    label: "Start Date",
    type: ["ind", "comp"],
  },

  {
    name: "expireDate",
    label: "Expire Date",
    type: ["ind", "comp"],
  },

  {
    name: "insuranceImage",
    label: "Insurance Picture",
    type: ["ind", "comp"],
  },

  {
    name: "cglInsurance",
    label: "CGL Insurance",
    type: ["comp"],
  },

  {
    name: "cglStartDate",
    label: "Start Date",
    type: ["comp"],
  },

  {
    name: "cglExpireDate",
    label: "Expire Date",
    type: ["comp"],
  },

  {
    name: "cglinsuranceImage",
    label: "CGL Picture",
    type: ["comp"],
  },
  {
    name: "accountHolder",
    label: "Account Holder (Name)",

    type: ["emp", "ind", "comp"],
  },
  {
    name: "bankName",
    label: "Bank Name",

    type: ["emp", "ind", "comp"],
  },
  {
    name: "routing",
    label: "Routing #",

    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",

    type: ["emp", "ind", "comp"],
  },
  {
    name: "account",
    label: "Account #",

    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmAccount",
    label: "Confirm Account #",

    type: ["emp", "ind", "comp"],
  },

  {
    name: "accountHolderOpt",
    label: "Account Holder (Name)",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "bankNameOpt",
    label: "Bank Name",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "routingOpt",
    label: "Routing #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmRoutingOpt",
    label: "Confirm Routing #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "accountOpt",
    label: "Account #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "confirmAccountOpt",
    label: "Confirm Account #",
    type: ["emp", "ind", "comp"],
  },
  {
    name: "profile",
    label: "Picture for Profile",
    type: ["emp", "ind", "comp"],
  },
];
//Workforce (Admin) Fields For Workforce Page
export const workforceEmployeeInputFields = [
 
  {
    name: "email",
    label: "Email",
  },
  {
    name: "empRole",
    label: "Role",
  },
 
];

export const workforceCompanyInputFields = [
  {
    name: "email",
    label: "Email",
  },
  {
    name: "compRole",
    label: "Role",
  },
];

export const workforceIndividualInputFields = [
  {
    name: "email",
    label: "Email",
  },
  {
    name: "indRole",
    label: "Role",
  },
];
export const selectWorkforce = [
  {
    option: "Employee",
  },
  {
    option: "Comapny/Contractor",
  },
  {
    option: "Individual/Contractor",
  },
];

//Admin fields for Admin Page
export const adminInputFields = [
  {
    name: "email",
    label: "Email",
  },
  {
    name: "role",
    label: "Role",
  },
];

//tabs for workforce comp
export const empTabs = [
  {
    name: "Basic Form",
  },
  {
    name: "W4 Form",
  },
  {
    name: "Employee Handbook",
  },
];

export const contrTabs = [
  {
    name: "Basic Form",
  },
  {
    name: "W9 Form",
  },
  {
    name: "Sign the Contract",
  },
];

export const workforce = [
  {
    option: "employee",
  },
  {
    option: "contractor Company",
  },
  {
    option: "contractor Individual",
  },
];

export const allOfficesLocation = [
  {
    id: 1,
    location: "Karachi"
  },
  {
    id: 2,
    location: "Lahore"
  },
  {
    id: 3,
    location: "Islamabad"
  },
  {
    id: 4,
    location: "Peshawer"
  },
  {
    id: 5,
    location: "Quetta"
  },
]

//field after submission (for users)
export const afterSubmitFields = (userData) => [
  {
    name: "fName",
    label: "First Name",
    input: userData?.fName,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "contactName1",
    label: "Contact 1 Name",
    input: userData?.contactName1,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "lName",
    label: "Last Name",
    input: userData?.lName,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "contactPhone1",
    label: "Contact 1 Phone",
    input: userData?.contactPhone1,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "ssn",
    label: "SSN #",
    input: userData?.ssn,
    access: ["Employee"],
  },
  {
    name: "ein",
    label: "EIN #",
    input: userData?.ein,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "contactName2",
    label: "Contact 2 Name",
    input: userData?.contactName2,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "phone",
    label: "Phone",
    input: userData?.phone,
    access: ["Employee", "Contractor Individual"],
  },
  {
    name: "phone",
    label: "Business Phone",
    input: userData?.phone,
    access: ["Contractor Company"],
  },
  {
    name: "contactPhone2",
    label: "Contact 2 Phone",
    input: userData?.contactPhone2,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "address",
    label: "Address",
    input: userData?.address,
    access: ["Employee", "Contractor Individual"],
  },
  {
    name: "address",
    label: "Business  Address",
    input: userData?.address,
    access: ["Contractor Company"],
  },
  {
    isblank: true,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "compName",
    label: "Company Name",
    input: userData?.compName,
    access: ["Contractor Company"],
  },
  {
    isblank: true,
    access: ["Contractor Company"],
  },
  {
    name: "startDate",
    label: "Start Date",
    input: userData?.startDate,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "make",
    label: "Make",
    input: userData?.make,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "expireDate",
    label: "Expire Date",
    input: userData?.expireDate,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "model",
    label: "Model",
    input: userData?.model,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "insuranceImage",
    label: "Insurance Picture",
    input: (
      <img
        src={userData?.insuranceImage}
        className="img-fluid"
        alt="No Img"
      />
    ),
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "year",
    label: "Year",
    input: userData?.year,
    access: ["Contractor Individual", "Contractor Company"],
  },

  {
    name: "cglInsurance",
    label: "CGL Insurance",
    input: userData?.cglInsurance,
    access: ["Contractor Individual", "Contractor Company"],
  },
  // {
  //   isblank: true,
  //   type: ["ind"],
  // },
  {
    name: "vehicleRegisterDate",
    label: "Registration Date",
    input: userData?.vehicleRegisterDate,
    access: ["Contractor Individual", "Contractor Company"],
  },

  {
    name: "cglStartDate",
    label: "Start Date",
    input: userData?.cglStartDate,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "vehicleImage",
    label: "Vehicle Picture",
    input: (
      <img src={userData?.vehicleImage} className="img-fluid" alt="No Img" />
    ),
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "cglExpireDate",
    label: "Expire Date",
    input: userData?.cglExpireDate,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    isblank: true,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "cglinsuranceImage",
    label: "CGL Picture",
    input: (
      <img
        src={userData?.cglinsuranceImage}
        className="img-fluid"
        alt="No Img"
      />
    ),
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    isblank: true,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "accountHolder",
    label: "Account Holder",
    input: userData?.accountHolder,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "accountHolderOpt",
    label: "Account Holder",
    input: userData?.accountHolderOpt ? userData?.accountHolderOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "bankName",
    label: "Bank Name",
    input: userData?.bankName,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "bankNameOpt",
    label: "Bank Name",
    input: userData?.bankNameOpt ? userData?.bankNameOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "routing",
    label: "Routing #",
    input: userData?.routing,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "routingOpt",
    label: "Routing #",
    input: userData?.routingOpt ? userData?.routingOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    input: userData?.confirmRouting,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    input: userData?.confirmRoutingOpt ? userData?.confirmRoutingOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "account",
    label: "Account #",
    input: userData?.account,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "accountOpt",
    label: "Account #",
    input: userData?.accountOpt ? userData?.accountOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "confirmAccount",
    label: "Confirm Account #",
    input: userData?.confirmAccount,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "confirmAccountOpt",
    label: "Confirm Account #",
    input: userData?.confirmAccountOpt ? userData?.confirmAccountOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "profile",
    label: "Picture for Profile",
    input: <img src={userData?.profile} className="img-fluid-profile" alt="No Img" />,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
];
//For mobile only (for users)
export const afterSubmitFieldsMobile = (userData) => [
  {
    name: "fName",
    label: "First Name",
    input: userData?.fName,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },

  {
    name: "lName",
    label: "Last Name",
    input: userData?.lName,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },

  {
    name: "ssn",
    label: "SSN #",
    input: userData?.ssn,
    access: ["Employee"],
  },
  {
    name: "ein",
    label: "EIN #",
    input: userData?.ein,
    access: ["Contractor Individual", "Contractor Company"],
  },

  {
    name: "phone",
    label: "Phone",
    input: userData?.phone,
    access: ["Employee", "Contractor Individual"],
  },
  {
    name: "phone",
    label: "Business Phone",
    input: userData?.phone,
    access: ["Contractor Company"],
  },

  {
    name: "address",
    label: "Address",
    input: userData?.address,
    access: ["Employee", "Contractor Individual"],
  },
  {
    name: "address",
    label: "Business  Address",
    input: userData?.address,
    access: ["Contractor Company"],
  },
  {
    isblank: true,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "compName",
    label: "Company Name",
    input: userData?.compName,
    access: ["Contractor Company"],
  },
  {
    name: "contactName1",
    label: "Contact 1 Name",
    input: userData?.contactName1,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "contactPhone1",
    label: "Contact 1 Phone",
    input: userData?.contactPhone1,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "contactName2",
    label: "Contact 2 Name",
    input: userData?.contactName2,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "contactPhone2",
    label: "Contact 2 Phone",
    input: userData?.contactPhone2,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },

  {
    name: "startDate",
    label: "Start Date",
    input: userData?.startDate,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "expireDate",
    label: "Expire Date",
    input: userData?.expireDate,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "insuranceImage",
    label: "Insurance Picture",
    input: (
      <img
        src={userData?.insuranceImage}
        className="img-fluid"
        alt="No Img"
      />
    ),
    access: ["Contractor Individual", "Contractor Company"],
  },

  {
    name: "cglInsurance",
    label: "CGL Insurance",
    input: userData?.cglInsurance,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "cglStartDate",
    label: "Start Date",
    input: userData?.cglStartDate,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "cglExpireDate",
    label: "Expire Date",
    input: userData?.cglExpireDate,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    isblank: true,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "cglinsuranceImage",
    label: "CGL Picture",
    input: (
      <img
        src={userData?.cglinsuranceImage}
        className="img-fluid"
        alt="No Img"
      />
    ),
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "make",
    label: "Make",
    input: userData?.make,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "model",
    label: "Model",
    input: userData?.model,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "year",
    label: "Year",
    input: userData?.year,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "vehicleRegisterDate",
    label: "Registration Date",
    input: userData?.vehicleRegisterDate,
    access: ["Contractor Individual", "Contractor Company"],
  },
  {
    name: "vehicleImage",
    label: "Vehicle Picture",
    input: (
      <img src={userData?.vehicleImage} className="img-fluid" alt="No Img" />
    ),
    access: ["Contractor Individual", "Contractor Company"],
  },

  {
    name: "accountHolder",
    label: "Account Holder",
    input: userData?.accountHolder,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "bankName",
    label: "Bank Name",
    input: userData?.bankName,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "routing",
    label: "Routing #",
    input: userData?.routing,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    input: userData?.confirmRouting,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "account",
    label: "Account #",
    input: userData?.account,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "confirmAccount",
    label: "Confirm Account #",
    input: userData?.confirmAccount,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "accountHolderOpt",
    label: "Account Holder",
    input: userData?.accountHolderOpt ? userData?.accountHolderOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "bankNameOpt",
    label: "Bank Name",
    input: userData?.bankNameOpt ? userData?.bankNameOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "routingOpt",
    label: "Routing #",
    input: userData?.routingOpt ? userData?.routingOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    input: userData?.confirmRoutingOpt ? userData?.confirmRoutingOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "accountOpt",
    label: "Account #",
    input: userData?.accountOpt ? userData?.accountOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "confirmAccountOpt",
    label: "Confirm Account #",
    input: userData?.confirmAccountOpt ? userData?.confirmAccountOpt : null,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
  {
    name: "profile",
    label: "Picture for Profile",
    input: <img src={userData?.profile} className="img-fluid-profile" alt="No Img" />,
    access: ["Employee", "Contractor Individual", "Contractor Company"],
  },
];

//field after submission (for Admin)
export const afterSubmitFieldsAdmin = (userData) => [
  {
    name: "fName",
    label: "First Name",
    input: userData?.fName,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "contactName1",
    label: "Contact 1 Name",
    input: userData?.contactName1,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "lName",
    label: "Last Name",
    input: userData?.lName,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "contactPhone1",
    label: "Contact 1 Phone",
    input: userData?.contactPhone1,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "ssn",
    label: "SSN #",
    input: userData?.ssn,
    access: ["/Employee-Workforce"],
  },
  {
    name: "ein",
    label: "EIN #",
    input: userData?.ein,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "contactName2",
    label: "Contact 2 Name",
    input: userData?.contactName2,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "phone",
    label: "Phone",
    input: userData?.phone,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce"],
  },
  {
    name: "phone",
    label: "Business Phone",
    input: userData?.phone,
    access: ["/Contractor-Company-Workforce"],
  },
  {
    name: "contactPhone2",
    label: "Contact 2 Phone",
    input: userData?.contactPhone2,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "address",
    label: "Address",
    input: userData?.address,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce"],
  },
  {
    name: "address",
    label: "Business  Address",
    input: userData?.address,
    access: ["/Contractor-Company-Workforce"],
  },
  {
    isblank: true,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "compName",
    label: "Company Name",
    input: userData?.compName,
    access: ["/Contractor-Company-Workforce"],
  },
  {
    isblank: true,
    access: ["/Contractor-Company-Workforce"],
  },
  {
    name: "startDate",
    label: "Start Date",
    input: userData?.startDate,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "make",
    label: "Make",
    input: userData?.make,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "expireDate",
    label: "Expire Date",
    input: userData?.expireDate,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "model",
    label: "Model",
    input: userData?.model,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "insuranceImage",
    label: "Insurance Picture",
    input: (
      <img
        src={userData?.insuranceImage}
        className="img-fluid"
        alt="No Img"
      />
    ),
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "year",
    label: "Year",
    input: userData?.year,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },

  {
    name: "cglInsurance",
    label: "CGL Insurance",
    input: userData?.cglInsurance,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  // {
  //   isblank: true,
  //   type: ["ind"],
  // },
  {
    name: "vehicleRegisterDate",
    label: "Registration Date",
    input: userData?.vehicleRegisterDate,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },

  {
    name: "cglStartDate",
    label: "Start Date",
    input: userData?.cglStartDate,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "vehicleImage",
    label: "Vehicle Picture",
    input: (
      <img src={userData?.vehicleImage} className="img-fluid" alt="No Img" />
    ),
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "cglExpireDate",
    label: "Expire Date",
    input: userData?.cglExpireDate,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    isblank: true,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "cglinsuranceImage",
    label: "CGL Picture",
    input: (
      <img
        src={userData?.cglinsuranceImage}
        className="img-fluid"
        alt="No Img"
      />
    ),
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    isblank: true,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "accountHolder",
    label: "Account Holder",
    input: userData?.accountHolder,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "accountHolderOpt",
    label: "Account Holder",
    input: userData?.accountHolderOpt ? userData?.accountHolderOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "bankName",
    label: "Bank Name",
    input: userData?.bankName,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "bankNameOpt",
    label: "Bank Name",
    input: userData?.bankNameOpt ? userData?.bankNameOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "routing",
    label: "Routing #",
    input: userData?.routing,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "routingOpt",
    label: "Routing #",
    input: userData?.routingOpt ? userData?.routingOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    input: userData?.confirmRouting,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    input: userData?.confirmRoutingOpt ? userData?.confirmRoutingOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "account",
    label: "Account #",
    input: userData?.account,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "accountOpt",
    label: "Account #",
    input: userData?.accountOpt ? userData?.accountOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "confirmAccount",
    label: "Confirm Account #",
    input: userData?.confirmAccount,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "confirmAccountOpt",
    label: "Confirm Account #",
    input: userData?.confirmAccountOpt ? userData?.confirmAccountOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "profile",
    label: "Picture for Profile",
    input: <img src={userData?.profile} className="img-fluid-profile" alt="No Img" />,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
];
//For mobile only (for Admin)
export const afterSubmitFieldsMobileAdmin = (userData) => [
  {
    name: "fName",
    label: "First Name",
    input: userData?.fName,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },

  {
    name: "lName",
    label: "Last Name",
    input: userData?.lName,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },

  {
    name: "ssn",
    label: "SSN #",
    input: userData?.ssn,
    access: ["/Employee-Workforce"],
  },
  {
    name: "ein",
    label: "EIN #",
    input: userData?.ein,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },

  {
    name: "phone",
    label: "Phone",
    input: userData?.phone,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce"],
  },
  {
    name: "phone",
    label: "Business Phone",
    input: userData?.phone,
    access: ["/Contractor-Company-Workforce"],
  },

  {
    name: "address",
    label: "Address",
    input: userData?.address,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce"],
  },
  {
    name: "address",
    label: "Business  Address",
    input: userData?.address,
    access: ["/Contractor-Company-Workforce"],
  },
  {
    isblank: true,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "compName",
    label: "Company Name",
    input: userData?.compName,
    access: ["/Contractor-Company-Workforce"],
  },
  {
    name: "contactName1",
    label: "Contact 1 Name",
    input: userData?.contactName1,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "contactPhone1",
    label: "Contact 1 Phone",
    input: userData?.contactPhone1,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "contactName2",
    label: "Contact 2 Name",
    input: userData?.contactName2,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "contactPhone2",
    label: "Contact 2 Phone",
    input: userData?.contactPhone2,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },

  {
    name: "startDate",
    label: "Start Date",
    input: userData?.startDate,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "expireDate",
    label: "Expire Date",
    input: userData?.expireDate,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "insuranceImage",
    label: "Insurance Picture",
    input: (
      <img
        src={userData?.insuranceImage}
        className="img-fluid"
        alt="No Img"
      />
    ),
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },

  {
    name: "cglInsurance",
    label: "CGL Insurance",
    input: userData?.cglInsurance,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "cglStartDate",
    label: "Start Date",
    input: userData?.cglStartDate,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "cglExpireDate",
    label: "Expire Date",
    input: userData?.cglExpireDate,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    isblank: true,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "cglinsuranceImage",
    label: "CGL Picture",
    input: (
      <img
        src={userData?.cglinsuranceImage}
        className="img-fluid"
        alt="No Img"
      />
    ),
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "make",
    label: "Make",
    input: userData?.make,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "model",
    label: "Model",
    input: userData?.model,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "year",
    label: "Year",
    input: userData?.year,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "vehicleRegisterDate",
    label: "Registration Date",
    input: userData?.vehicleRegisterDate,
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "vehicleImage",
    label: "Vehicle Picture",
    input: (
      <img src={userData?.vehicleImage} className="img-fluid" alt="No Img" />
    ),
    access: ["/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },

  {
    name: "accountHolder",
    label: "Account Holder",
    input: userData?.accountHolder,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "bankName",
    label: "Bank Name",
    input: userData?.bankName,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "routing",
    label: "Routing #",
    input: userData?.routing,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    input: userData?.confirmRouting,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "account",
    label: "Account #",
    input: userData?.account,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "confirmAccount",
    label: "Confirm Account #",
    input: userData?.confirmAccount,
    isOpt: userData?.accountHolderOpt ? true : false,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "accountHolderOpt",
    label: "Account Holder",
    input: userData?.accountHolderOpt ? userData?.accountHolderOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "bankNameOpt",
    label: "Bank Name",
    input: userData?.bankNameOpt ? userData?.bankNameOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "routingOpt",
    label: "Routing #",
    input: userData?.routingOpt ? userData?.routingOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "confirmRouting",
    label: "Confirm Routing #",
    input: userData?.confirmRoutingOpt ? userData?.confirmRoutingOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "accountOpt",
    label: "Account #",
    input: userData?.accountOpt ? userData?.accountOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "confirmAccountOpt",
    label: "Confirm Account #",
    input: userData?.confirmAccountOpt ? userData?.confirmAccountOpt : null,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
  {
    name: "profile",
    label: "Picture for Profile",
    input: <img src={userData?.profile} className="img-fluid-profile" alt="No Img" />,
    access: ["/Employee-Workforce", "/Contractor-Individual-Workforce", "/Contractor-Company-Workforce"],
  },
];