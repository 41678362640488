import React, { createContext, useContext, useState } from 'react';

const AdminContext = createContext();

export const useAdminContext = () => {
  return useContext(AdminContext);
};

export const AdminProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  const value = {
    isAdmin,
    setIsAdmin,
  };

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>;
};