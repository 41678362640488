import React from "react";
import Buttons from "../Button/Buttons";

const Handbook = ({ path, pdf, apiData }) => {
  const handleFinish = () => {
    // const basicForm = localStorage.getItem("BasicForm");

    // const parsedData = JSON.parse(basicForm);

    // const withImage = {
    //   ...parsedData,
    //   profile: parsedData["profile"].fileList[0]?.originFileObj
    // }

    // const taxForm = pdf;
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

    const mergedData = {
      apiData,
      pdf,
      timestamp: currentTimestampInSeconds,
    };
    console.log("ALl Data>>>>", mergedData);
  };

  return (
    <div className="mt-3">
      Handbook
      <div>
        <Buttons type={"primary"} title={"Finish"} onClick={handleFinish} />
      </div>
    </div>
  );
};

export default Handbook;
