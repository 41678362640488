import { createSlice } from "@reduxjs/toolkit";
import { createAdmin, getAdmin } from "../ApiActions/AdminApi";

const initialState = {
  AdminData: [],
  getAdminData: [],
  isFecthing: false,
  error: "",
};

const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (obj) => {
    obj.addCase(createAdmin.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(createAdmin.fulfilled, (state, action) => {
      // console.log("Fulfilled", action.payload);
      state.isFecthing = false;
      state.AdminData = action.payload;
    });
    obj.addCase(createAdmin.rejected, (state, action) => {
      console.log("rejected");
      state.isFecthing = false;
      state.error = action.error;
    });
    obj.addCase(getAdmin.pending, (state)=>{
    })
    obj.addCase(getAdmin.fulfilled, (state, action)=>{
       state.getAdminData = action.payload;
    })
    obj.addCase(getAdmin.rejected, (state, action)=>{
       state.error = action.error;
    })
  },
});
export default admin.reducer;