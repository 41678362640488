import { useSelector } from "react-redux";
import "./App.css";
import Layouts from "./Components/Layout/Layouts";
import Login from "../src/Components/Auth-forms/Login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { AdminProvider } from "./Store/ContextApi/AdminProvider";

function App() {
  const isUser = useSelector((state) => state.login?.auth);
  console.log(isUser);
  const [user, setUser] = useState(null);
  console.log(user);

  useEffect(() => {
    window.addEventListener("online", () =>
      toast.success(<div> &nbsp; You Are Online Now </div>)
    );
    window.addEventListener("offline", () =>
      toast.error(<div> &nbsp; Please Check Your Internet </div>)
    );
  });

  function decoder() {
    let token = localStorage.getItem("token");

    if (!token || typeof token !== "string") {
      console.log("Invalid or missing token");
      return null;
    }

    try {
      let decoded = jwtDecode(token);
      console.log(decoded);

      if (!decoded || !decoded.tokenData) {
        console.log("Decoding failed or token is undefined");
        return null;
      }

      localStorage.setItem("username", decoded.tokenData.username);
      return decoded.tokenData.username;
    } catch (error) {
      console.log("Error decoding token:", error.message);
      return null;
    }
  }

  useEffect(() => {
    const decodedUser = decoder();

    setUser(decodedUser);
  }, [isUser]);
  return (
    <div>
      <Routes>
        <Route
          path="/*"
          element={
            user || isUser ? (
              <AdminProvider>
                <Layouts />
              </AdminProvider>
            ) : (
              <Login />
            )
          }
        />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
