import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../ApiRoute/ApiRoute";

export const getRole = createAsyncThunk(
  //action type string
  "multipleAccounts/getMultipleAccounts",
  //callback func
  async (data, { rejectWithValue }) => {
    console.log(data)
    try {
      const res = (await userRequest.post(`/next`, data))
      console.log(res);
      return res.data
    } catch (err) {
      return rejectWithValue("error occured while fetching");
    }
  }
);
