import React, { useEffect, useState } from "react";
import BasicForm from "../../Components/Employee/BasicForm";
import W4Form from "../../Components/Employee/W4Form";
import Handbook from "../../Components/Employee/Handbook";
import { Form, Select } from "antd";
import { contrTabs, empTabs, workforce } from "../../Utils/dataUtils";
import { userUpdataData } from "../../Store/ApiActions/employeeDataApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Person3Icon from "@mui/icons-material/Person3";
import Cards from "../../Components/Cards/Cards";
import { getWorkforce } from "../../Store/ApiActions/employeeWorkforceApi";
import { useAdminContext } from "../../Store/ContextApi/AdminProvider";
import { getIndividualWorkforce } from "../../Store/ApiActions/individualWorkforceApi";
import { getCompanyWorkforce } from "../../Store/ApiActions/companyWorkforceApi";

const cardsInfo = [
  {
    title: "Employees",
    icon: <Diversity1Icon />,
    url: "/employee",
    access: ["Employee", "Admin", "Super Admin"],
  },
  {
    title: "Individual",
    icon: <Person3Icon />,
    url: "/individual",
    access: ["Admin", "Super Admin"],
  },
  {
    title: "Company",
    icon: <ApartmentIcon />,
    url: "/company",
    access: ["Admin", "Super Admin"],
  },
];

const WorkforcePortal = ({ path, iscollapsed , value }) => {
  console.log("iscollapsed",iscollapsed)
  const { isAdmin } = useAdminContext();
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();

  let workforceDataSelector;
  if (isAdmin === "Employee") {
    workforceDataSelector = (state) => state.workforce?.getworkforceData;
  } else if (isAdmin === "Contractor Company") {
    workforceDataSelector = (state) =>
      state.companyWorkforce?.getCompnayworkforceData;
  } else {
    workforceDataSelector = (state) =>
      state.individualWorkforce?.getIndividualworkforceData;
  }

  const allWorkforceData = useSelector(workforceDataSelector);
  console.log("allWorkforceData", allWorkforceData);
  const getUserData = () => {
    const userEmail = localStorage.getItem("userEmail");

    const loginUser = allWorkforceData?.find(
      (item) => userEmail === item.email
    );
    setUserData(loginUser);
  };

  useEffect(() => {
    getUserData();
  }, [allWorkforceData]);

  useEffect(() => {
    if (isAdmin === "Employee") {
      dispatch(getWorkforce());
    } else if (isAdmin === "Contractor Company") { 
      dispatch(getCompanyWorkforce());
    } else {
      dispatch(getIndividualWorkforce());
    }
  }, [dispatch, isAdmin]);

  const [selectedTab, setSelectedTab] = useState("Basic Form");
  const [basicFormData, setBasicFormData] = useState({});
  const [workforceData, setWorkforceData] = useState("");
  const [formData, setFormData] = useState({ profile: {}, allData: {} });

  // Check if there is saved form data when the component mounts
  useEffect(() => {
    const savedData = localStorage.getItem("BasicForm");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData(parsedData);
    }
  }, []);

  useEffect(() => {
    setWorkforceData(path);
  }, [path]);

  const handleClick = (e) => {
    //Admin should allowed to acces all form with out filled current form
    if (isAdmin === "Admin" || isAdmin === "Super Admin" || userData?.formStatus !== 0) {
      setSelectedTab(e.target.value);
    }
     else {
      //workforce should not allowed to access another form with out filled current form
      if (e.target.value === "Basic Form") {
        setSelectedTab("Basic Form");
        window.location.reload();
      } else if (
        e.target.value === "W4 Form" &&
        selectedTab === "Employee Handbook"
      ) {
        setSelectedTab("W4 Form");
      } else if (
        e.target.value === "W9 Form" &&
        selectedTab === "Sign the Contract"
      ) {
        setSelectedTab("W9 Form");
      }
    }
  };

  const form = new FormData();

  //in basic form
  const handleFormNext = async (
    values,
    basicForm,
    profileImage,
    insuranceImage,
    cglImage,
    vehicleImage
  ) => {
    console.log("image>>", profileImage);
    //when accout and routing field is match with thier confirms field
    if (
      values.routing === values.confirmRouting &&
      values.account === values.confirmAccount &&
      values.routingOpt === values.confirmRoutingOpt &&
      values.accountOpt === values.confirmAccountOpt
    ) {
      if (isAdmin === "Employee") {
        localStorage.setItem("BasicForm", JSON.stringify(values));
        const fields = {
          fName: values["fName"],
          lName: values["lName"],
          ssn: values["ssn"],
          address: values["address"],
        };
        setBasicFormData(fields);
        setFormData({
          ...formData,
          profile: profileImage,
          allData: values,
        });
        setSelectedTab("W4 Form");
      } else {
        const allValues = {
          ...values,
          startDate: values["startDate"]
            ? values["startDate"].format("YYYY-MM-DD")
            : basicForm["startDate"],
          expireDate: values["expireDate"]
            ? values["expireDate"].format("YYYY-MM-DD")
            : basicForm["expireDate"],
          vehicleRegisterDate: values["vehicleRegisterDate"]
            ? values["vehicleRegisterDate"].format("YYYY-MM-DD")
            : basicForm["vehicleRegisterDate"],
          cglStartDate: values["cglStartDate"]
            ? values["cglStartDate"].format("YYYY-MM-DD")
            : basicForm["cglStartDate"],
          cglExpireDate: values["cglExpireDate"]
            ? values["cglExpireDate"].format("YYYY-MM-DD")
            : basicForm["cglExpireDate"],
          // profile: profileImage,
          // insuranceImage: insuranceImage,
          // cglinsuranceImage: cglImage,
          // vehicleImage: vehicleImage,
        };
        console.log("allvalue>>", allValues);
        // Object.entries(allValues).forEach(([name, value]) => {
        //   if (
        //     name !== "profile" ||
        //     name !== "insuranceImage" ||
        //     name !== "cglinsuranceImage" ||
        //     name !== "vehicleImage"
        //   ) {
        //     form.append(name, value === undefined ? "" : value);
        //   }
        // });
        // form.append("profile", profileImage);
        // form.append("insuranceImage", insuranceImage);
        // form.append("cglinsuranceImage", cglImage);
        // form.append("vehicleImage", vehicleImage);
        // form.append("formStatus", 1);
        // // form.append("userStatus", 0);
        // // form.append("activeStatus", 0);

        // for (var pair of form.entries()) {
        //   console.log(pair[0], "", pair[1]);
        // }
        // const response = await dispatch(userUpdataData(form));
        // console.log(response);
        // if (response.payload.status) {
        //   setTimeout(() => {
        //     toast.success("Added Succesfuly");
        //   }, 2000);
        // }
        // const fileds = {
        //   ...values,
        //   profile:image
        // }
        // console.log("image data",fileds);

        localStorage.setItem("BasicForm", JSON.stringify(allValues));
        const fields = {
          fName: values["fName"],
          lName: values["lName"],
          ein: values["ein"],
          address: values["address"],
          // account: values["account"],
        };
        setBasicFormData(fields);
        setFormData({
          ...formData,
          profile: profileImage,
          insuranceImage: insuranceImage,
          cglinsuranceImage: cglImage,
          vehicleImage: vehicleImage,
          allData: allValues,
        });
        setSelectedTab("W9 Form");
      }
    }
    //when accout and routing field are not match with thier confirms field
    else if (
      values.routing !== values.confirmRouting ||
      values.routingOpt !== values.confirmRoutingOpt
    ) {
      toast.warning("Please match Routing information");
    } else if (
      values.account !== values.confirmAccount ||
      values.accountOpt !== values.confirmAccountOpt
    ) {
      toast.warning("Please match Accounts information");
    }
  };

  return (
    <div>
      {workforceData === "/form" ? (
        <div className="home-cards w-100 py-2 text-center">
          <div className="d-flex flex-wrap flex-grow-2 justify-content-around mt-4">
            {cardsInfo.map((item) => (
              // <Cards title={item.title} icon={item.icon} url={item.url} />
              <Cards
                title={item.title}
                icon={item.icon}
                url={item.url}
                access={item.access}
              />
            ))}
          </div>
        </div>
      ) : (
        <>
          <div
            className=" justify-content-center  overflow-y-scroll"
            style={{height: "80vh"}}
          >
            <div
              className={iscollapsed ? "nav-bar" : "nav-bar-shrink"}
              // style={ iscollapsed? {width: "83%"} : { width: "92%" }}
            >
              {(workforceData === "/Employee-Form" ? empTabs : contrTabs)?.map(
                (item) => {
                  // console.log(item);
                  return (
                    <div key={item.name}>
                      <div
                        className={`${
                          selectedTab === item.name ? "borders-bottom" : ""
                        }`}
                      >
                        <button
                          style={
                            isAdmin === "Admin" || isAdmin === "Super Admin" || userData?.formStatus !== 0
                              ? {}
                              : selectedTab === "Basic Form" &&
                                (item.name === "W4 Form" ||
                                  item.name === "Employee Handbook" ||
                                  item.name === "W9 Form" ||
                                  item.name === "Sign the Contract")
                              ? { cursor: "not-allowed" }
                              : (selectedTab === "W4 Form" &&
                                  item.name === "Employee Handbook") ||
                                (selectedTab === "W9 Form" &&
                                  item.name === "Sign the Contract")
                              ? { cursor: "not-allowed" }
                              : {}
                          }
                          value={item.name}
                          onClick={handleClick}
                          className={` ${
                            selectedTab === item.name
                              ? "border-0 nav-bar-btn tab-text-color"
                              : "border-0 nav-bar-btn"
                          }`}
                        >
                          {item.name}
                        </button>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <div className="border-0 mt-3 pt-4">
              {selectedTab === "Basic Form" ? (
                <BasicForm
                  onFinish={handleFormNext}
                  path={workforceData}
                  formData={formData}
                  allWorkforceData={allWorkforceData}
                />
              ) : selectedTab === "W4 Form" || selectedTab === "W9 Form" ? (
                <W4Form
                  basicFormData={basicFormData}
                  path={workforceData}
                  allBasicformData={formData}
                  allWorkforceData={allWorkforceData}
                  iscollapsed={iscollapsed}
                />
              ) : selectedTab === "Employee Handbook" ? (
                <Handbook
                  path={workforceData}
                  allWorkforceData={allWorkforceData}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WorkforcePortal;
