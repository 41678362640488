import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../ApiRoute/ApiRoute";

export const getAllRoles = createAsyncThunk(
    //action type string
    "eroles/getRoles",
    //callback func
    async (data, { rejectWithValue }) => {
      try {
        let res = await userRequest.get("/selectRoles");
        console.log("response>>>",res);
        return res.data.data;
      } catch (err) {
        return rejectWithValue("error occured while fetching");
      }
    }
  );