import { Button } from "antd";
import React from "react";

const Buttons = (props) => {
  const {
    title,
    onClick,
    icon,
    style,
    type,
    className,
    htmlType,
    disabled,
    size,
  } = props;
  return (
    <Button
      className={className}
      icon={icon}
      style={style}
      onClick={onClick}
      type={type}
      htmlType={htmlType}
      disabled={disabled}
      size={size}
    >
      {title}
    </Button>
  );
};

export default Buttons;
