import React, { useEffect } from "react";
import { useState } from "react";
const ViewUploadFile = ({ particularUserData }) => {
  console.log(particularUserData);
  const [pdfData, setPdfData] = useState("");
  console.log(pdfData);

  useEffect(() => {
    const pdf = particularUserData.pdf;
    setPdfData(pdf);
    console.log(pdf);
  }, [particularUserData]);

  return (
    <div className="pdf-container">
      {pdfData ? (
        <iframe className="pdf-iframe" src={pdfData} />
      ) : (
        <div className="text-center mt-5">
          {" "}
          <h2 className="mt-5" style={{ color: "#4096FF" }}>
            This {particularUserData?.userRole} Form Not Filled Yet
          </h2>
        </div>
      )}
    </div>
  );
};

export default ViewUploadFile;
