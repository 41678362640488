import React, { useCallback, useEffect, useMemo, useState } from "react";
import Buttons from "../../Components/Button/Buttons";
import { useDispatch } from "react-redux";
import Tables from "../../Components/Tables/Tables";
import { toast } from "react-toastify";
import Modals from "../../Components/Modal/Modals";
import {
  createWorkforce,
  getWorkforce,
  updateWorkforce,
} from "../../Store/ApiActions/employeeWorkforceApi";
import { useSelector } from "react-redux";
import { workforceEmployeeInputFields as inputFields } from "../../Utils/dataUtils";
import DialogModal from "../../Components/Dialog/DialogModal";
import { PlusCircleOutlined } from "@ant-design/icons";

const status = [
  {
    key: "formStatus",
    name: "Form Status",
    isenable: false,
    status0: "Pending",
    status1: "Filled",
  },
  {
    key: "userStatus",
    name: "User Status",
    isenable: true,
    status0: "Pending",
    status1: "Accepted",
    status2: "Rejected",
  },
  {
    key: "activeStatus",
    name: "Active Status",
    isenable: true,
    status0: "Pending",
    status1: "Active",
    status2: "Deactive",
  },
];
const offices = [
  "Karchi",
  "Lahore",
  "Islamabad",
  "Peshawar",
  "Multan",
  "Quetta",
];
const userStatus = ["Accepted", "Rejected"];
const activeStatus = ["Active", "Deactive"];

const EmployeeWorkforce = () => {
  const [rowId, setRowId] = useState(null);
  console.log(rowId);
  const [isloading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id) => {
    setOpen(true);
    setRowId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const allWorkforceData = useSelector(
    (state) => state.workforce?.getworkforceData
  );

  useEffect(() => {
    dispatch(getWorkforce());
  }, [dispatch]);

  useEffect(() => {
    if (allWorkforceData) {
      setTableData(allWorkforceData);
    }
  }, [allWorkforceData]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (values) => {
    const workforceData = {
      ...values,
      transferToBranch: "",
      formStatus: 0,
      userStatus: 0,
      activeStatus: 0,
    };
    console.log("workforceData", workforceData);
    setIsLoading(true);
    const response = await dispatch(createWorkforce(workforceData));
    console.log(response);

    console.log(response.payload.status);
    if (response.payload.status) {
      dispatch(getWorkforce());
      setTimeout(() => {
        setIsLoading(false);
        setIsModalOpen(false);
        toast.success("Added Succesfully");
      }, 2000);
    } else {
      setIsLoading(false);
      setIsModalOpen(false);
      toast.warning(response.payload.message);
    }
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    values = { ...values, id: row.original.id };
    console.log(values);

    if (!Object.keys(validationErrors).length) {
      exitEditingMode();
      const updatedResult = await dispatch(updateWorkforce(values));

      if (updatedResult.payload.status === 200) {
        toast.success("Updated Successfully");
        dispatch(getWorkforce());
      }
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          setValidationErrors({
            ...validationErrors,
          });
        },
      };
    },
    [validationErrors]
  );

  const columns = useMemo(
    () => [
      // {
      //   accessorKey: "id",
      //   header: "Id",
      //   size: 75,
      //   enableEditing: false,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //   }),
      // },
      ...status.map((item) => ({
        accessorKey: item.key,
        header: item.name,
        enableEditing: item.isenable,
        size: 100,
        editVariant: "select",
        editSelectOptions: (item.key === "userStatus"
          ? userStatus
          : activeStatus
        ).map((status, index) => ({ value: index + 1, label: status })),

        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.state,
          helperText: validationErrors?.state,
        },
        Cell: ({ cell }) => {
          const value = cell.row.original;
          const state =
            item.key === "formStatus"
              ? value.formStatus
              : item.key === "userStatus"
              ? value.userStatus
              : value.activeStatus;
          return state === 0 ? (
            <div className="status0 w-100 text-center">{item.status0}</div>
          ) : state === 1 ? (
            <div
              className={
                item.isenable
                  ? "status1-active w-100 text-center "
                  : "status1-filled  w-100 text-center"
              }
            >
              {item.status1}
            </div>
          ) : (
            <div className="status1-deactived  w-100 text-center">
              {item.status2}
            </div>
          );
        },
      })),
      {
        accessorKey: "transferToBranch",
        header: "Office",
        size: 150,
        Cell: ({ cell }) => {
          const value = cell.row.original.transferToBranch;
          return value === "" || value === null ? "TBD" : value;
        },
        editVariant: "select",
        editSelectOptions: offices,

        muiEditTextFieldProps: {
          select: true,
          error: !!validationErrors?.state,
          helperText: validationErrors?.state,
        },
      },

      {
        accessorKey: "empRole",
        header: "Work Force",
        size: 75,
        enableEditing: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 150,
        enableEditing: false,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
    ],
    [status, getCommonEditTextFieldProps, tableData]
  );

  return (
    <div>
      <DialogModal
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        open={open}
        setOpen={setOpen}
        rowId={rowId}
        setRowId={setRowId}
        allWorkforceData={allWorkforceData}
      />
      <Buttons
        icon={<PlusCircleOutlined style={{ fontSize: "17px" }} />}
        title={"Create WorkForce"}
        className={"mt-2 mx-3 btn-ui"}
        // type={"primary"}
        onClick={handleModalOpen}
      />

      <Modals
        isModalOpen={isModalOpen}
        handleCancel={handleModalClose}
        onFinish={handleSubmit}
        inputFields={inputFields}
        // selectWorkforce={selectWorkforce}
        isloading={isloading}
        title={"Create Employee WorkForce"}
      />
      <Tables
        columns={columns}
        tableData={tableData}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleSaveRowEdits={handleSaveRowEdits}
        handleCancelRowEdits={handleCancelRowEdits}
        title={"Employees"}
      />
    </div>
  );
};

export default EmployeeWorkforce;
