import { createAsyncThunk } from "@reduxjs/toolkit";
import {  publicRequest, userRequest } from "../../ApiRoute/ApiRoute";

export const sendloginCred = createAsyncThunk(
  //action type string
  "login/sendLoginCred",
  //callback func
  async (loginData, { rejectWithValue }) => {
    console.log(loginData )
    try {
      const response = await publicRequest.post("/login", loginData);
      console.log(response);
      const getToken = response.data?.data.token
      localStorage.setItem("token", getToken);
      console.log(getToken)
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue("error occured while fetching");
    }
  }
  
);

export const logoutSuccess = createAsyncThunk(
  'login/logout',
 async (_ ,{rejectWithValue}) =>{
  try{
    localStorage.clear();
  } catch(err){
 return rejectWithValue ("error while logout")
  }
 })




