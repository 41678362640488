import { Button, Form, Input, Typography } from "antd";
import classes from "./auth-form.module.css";
import logo from "../../Asset/Images/UGALogo.png";
import { useDispatch } from "react-redux";
import { sendloginCred } from "../../Store/ApiActions/loginApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const inputField = [
  {
    name: "username",
    label: "User Name",
  },
  {
    name: "email",
    label: "Email",
  },
  {
    name: "password",
    label: "Password",
  },
  {
    name: "confirmPassword",
    label: "Confirm Password",
  },
];

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    if (values.password === values.confirmPassword) {
      toast.success("Sign Up Successfully");
      // navigate("/login");
    } else {
      toast.error("Password not match");
    }
    console.log(values);
    const { email, password } = values;
    console.log(email, password);
    if (email === "" && password === "") {
      return;
    }

    try {
      const result = await dispatch(sendloginCred({ email, password }));
      console.log(result.payload.status);
      if (result.payload.status === true) {
        toast.success(result.payload.message);
        localStorage.setItem("email", email);
        navigate("/");
      } else {
        toast.error(result.payload.message);
      }
    } catch (err) {}
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.signupBox} `}>
        <div className="text-center">
          <img
            src={logo}
            style={{ filter: "contrast(0.5)" }}
            alt="logo"
            height={"90px"}
            width={"200px"}
            // className="img-fluid"
          />
        </div>
        <Title
          level={2}
          className="mt-2 text-center"
          style={{ fontFamily: "Poppins", color: "#4096FF" }}
        >
          Sign Up
        </Title>
        <Form
          className={classes.signupForm}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
        >
          {inputField.map((item) => (
            <Form.Item
              style={{ fontFamily: "Poppins" }}
              labelCol={{
                span:
                  item.name === "username"
                    ? 8
                    : item.name === "email"
                    ? 4
                    : item.name === "password"
                    ? 7
                    : 12,
              }}
              wrapperCol={{
                span: 27,
              }}
              key={item.name}
              label={item.label}
              name={[item.name]}
              initialValue={
                item.name === "email" ? "sampleEmail@gmail.com" : ""
              }
              rules={[
                {
                  required: item.name === "email" ? false : true,
                  message: `Enter your ${item.label}!`,
                },
              ]}
            >
              {item.name === `password` || item.name === `confirmPassword` ? (
                <Input.Password placeholder={`Enter your ${item.label}`} />
              ) : (
                <Input
                  placeholder={`Enter your ${item.label}`}
                  disabled={item.name === "email" ? true : false}
                />
              )}
            </Form.Item>
          ))}

          <Form.Item
            wrapperCol={{
              span: 27,
            }}
          >
            <Button type="primary" htmlType="submit" block size="large">
              SignUp
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default SignUp;
