import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkforce } from "../../Store/ApiActions/employeeWorkforceApi";
import "./UserHistory.css";
import { useAdminContext } from "../../Store/ContextApi/AdminProvider";
import { getCompanyWorkforce } from "../../Store/ApiActions/companyWorkforceApi";
import { getIndividualWorkforce } from "../../Store/ApiActions/individualWorkforceApi";

const UserHistory = () => {
  const { isAdmin } = useAdminContext();
  const dispatch = useDispatch();
  // Get LoggedIn Users  Data
  const [userData, setUserData] = useState(null);
  console.log("response1", userData);
  let workforceDataSelector;
  if (isAdmin === "Employee") {
    workforceDataSelector = (state) => state.workforce?.getworkforceData;
  } else if (isAdmin === "Contractor Company") {
    workforceDataSelector = (state) =>
      state.companyWorkforce?.getCompnayworkforceData;
  } else {
    workforceDataSelector = (state) =>
      state.individualWorkforce?.getIndividualworkforceData;
  }

  const allWorkforceDataApi = useSelector(workforceDataSelector);
  const getUserData = () => {
    const userEmail = localStorage.getItem("userEmail");

    const loginUser = allWorkforceDataApi?.find(
      (item) => userEmail === item.email
    );
    setUserData(loginUser);
  };

  useEffect(() => {
    getUserData();
  }, [allWorkforceDataApi]);

  useEffect(() => {
    if (isAdmin === "Employee") {
      dispatch(getWorkforce());
    } else if (isAdmin === "Contractor Company") {
      dispatch(getCompanyWorkforce());
    } else {
      dispatch(getIndividualWorkforce());
    }
  }, [dispatch, isAdmin]);

  return (
    <>
      <div className="status-container mt-3">
        <h2>Your Status</h2>
      </div>
      <div className="w-100 d-flex justify-content-center mt-3">
        {userData?.activeStatus === 1 ? (
          <div className=" status active text-center">
            Your Status is Active
          </div>
        ) : userData?.activeStatus === 2 ? (
          <div className="Status rejected text-center">
            Your Status is Rejected
          </div>
        ) : (
          <div className="Status pending text-center">
            {" "}
            Your Status is Pending
          </div>
        )}
      </div>
    </>
  );
};

export default UserHistory;
