import { MaterialReactTable } from "material-react-table";

import React from "react";
import Loader from "../Loader/Loader";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
} from "@ant-design/icons";

const Tables = (props) => {
  const {
    columns,
    tableData,
    handleDeleteRow,
    handleSaveRowEdits,
    handleCancelRowEdits,
    isEditing,
    handleClickOpen,
    title,
  } = props;
  return (
    <div className="mrt-table mx-3 mt-3">
      {tableData?.length > 0 ? (
        <MaterialReactTable
          displayColumnDefOptions={
            props.users
              ? ""
              : {
                  "mrt-row-actions": {
                    muiTableHeadCellProps: {
                      align: "center",
                    },
                    size: 50,
                  },
                }
          }
          columns={columns}
          data={tableData}
          editingMode="modal" // default
          enableColumnOrdering
          enableStickyHeader
          enableStickyFooter
          muiTableContainerProps={{ sx: { maxHeight: "420px" } }}
          initialState={{ density: "compact" }}
          enableEditing={isEditing ? false : true}
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          renderRowActions={
            isEditing
              ? ""
              : ({ row, table }) => (
                  <Box sx={{ display: "flex", gap: "2vmin" }}>
                    {handleClickOpen ? (
                      <Tooltip arrow placement="left" title="View">
                        <IconButton
                          onClick={(e) => handleClickOpen(row.original.id)}
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                    <Tooltip arrow placement="left" title="Edit">
                      <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditOutlined style={{ fontSize: "18px" }} />
                      </IconButton>
                    </Tooltip>
                    {handleClickOpen ? (
                      ""
                    ) : (
                      <Tooltip arrow placement="right" title="Delete">
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteRow(row)}
                        >
                          <DeleteOutlined style={{ fontSize: "18px" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                )
          }
          renderTopToolbarCustomActions={() => (
            <Box>
              <Typography variant="h5" fontFamily="poppins" className="mt-2">
                {title}
              </Typography>
            </Box>
          )}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Tables;
