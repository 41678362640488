import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import React from "react";
import TabsSwipe from "../Tabs/TabsSwipe";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DialogModal = (props) => {
  const {
    handleClose,
    handleClickOpen,
    open,
    setOpen,
    rowId,
    setRowId,
    allWorkforceData,
  } = props;
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className="mb-5" style={{ background: "#266092" }}>
          <Toolbar className="justify-content-between">
            <h5 className="mt-2">View User Submit Form</h5>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "7rem" }}
        >
          <TabsSwipe rowId={rowId} allWorkforceData={allWorkforceData} />
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogModal;
