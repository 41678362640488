import { createSlice } from "@reduxjs/toolkit";
import { createCompanyWorkforce, getCompanyWorkforce } from "../ApiActions/companyWorkforceApi";

;

const initialState = {
  companyWorkforceData: [],
  getCompnayworkforceData: [],
  isFecthing: false,
  error: "",
};

const workforce = createSlice({
  name: "companyWorkforce",
  initialState,
  reducers: {},
  extraReducers: (obj) => {
    //Create
    obj.addCase(createCompanyWorkforce.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(createCompanyWorkforce.fulfilled, (state, action) => {
      console.log(action);

      state.workforceData = action.payload;
      state.isFecthing = false;
    });
    obj.addCase(createCompanyWorkforce.rejected, (state, action) => {
      state.isFecthing = false;
      state.companyWorkforceData = [];
      state.error = action.error;
    });

    //Get
    obj.addCase(getCompanyWorkforce.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(getCompanyWorkforce.fulfilled, (state, action) => {
      console.log("Workforce reducer>>>", action.payload);
      state.getCompnayworkforceData = action.payload;
      state.isFecthing = false;
    });
    obj.addCase(getCompanyWorkforce.rejected, (state, action) => {
      state.isFecthing = false;
      state.error = action.error;
    });
  },
});

export default workforce.reducer;
