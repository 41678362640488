import { createSlice } from "@reduxjs/toolkit";
import { getAllRoles } from "../ApiActions/rolesApi";



const initialState = {

  getRoles: [],
  isFecthing: false,
  error: "",
};

const roles = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (obj) => {

    //Get
    obj.addCase(getAllRoles.pending, (state) => {
      state.isFecthing = true;
    });
    obj.addCase(getAllRoles.fulfilled, (state, action) => {
      state.getRoles = action.payload;
      state.isFecthing = false;
    });
    obj.addCase(getAllRoles.rejected, (state, action) => {
      state.isFecthing = false;
      state.error = action.error;
    });
  },
});

export default roles.reducer;
